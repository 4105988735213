import { Image, message, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountListService } from '../services/AccountListService';
import '../styles/Onboarding.scss';
import { touchEnd, touchMove, touchStart } from '../utils/touchfunctionality';
import { BASE_TITLE, returnSvg } from '../utils/utility';
import { getSearchParams } from './AccountList';
import { AccountListData } from '../types/api';

// Screen of first step for onboarding
const Onboarding1: React.FC = () => {
  const [pageIndex] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();

  // sets account limit per page
  const AccountLimit = 10;

  // set current page index
  const AccountPage = 1;

  // init the internationalization support
  const { t } = useTranslation();

  useEffect(() => {
    const isFsrAlreadySet = localStorage.getItem('fsr_name') !== null;
    const isLoginLogged = sessionStorage.getItem('login_logged') !== null;
    if (isFsrAlreadySet && isLoginLogged) return;

    // Load account details to capture FSR info to be used through the application
    AccountListService(getSearchParams(false, '', AccountLimit, AccountPage))
      .then((data: AccountListData) => {
        // handles if the account info returns error response
        if (data.length === 0 || data.error) {
          return;
        }
      })
      .catch(() => {
        // Handles error response
        message.error('Could not load FSR details. Please reload!');
      });

    // sets page title
    document.title = `${BASE_TITLE} - Onboarding - Information on the Go`;
  }, []);

  const handleClick = () => {
    navigate('/onboarding/2', { state: location });
  };

  return (
    <div
      className="onboarding-container"
      onTouchStart={touchStart(0)}
      onTouchMove={touchMove}
      onTouchEnd={touchEnd(navigate, 'onboarding/2', 'onboarding/1')}
    >
      <div className="img-container img-container1">
        <Image src="/media/onboarding1.png" preview={false} />
      </div>
      <div className="description">
        <div className="title-text">{t('informationOnGo')}</div>
        <div className="body-text">{t('welcomeString')}</div>
      </div>
      <div className="pagination-list">
        {/* list the dots */}
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) =>
          returnSvg(Boolean(pageIndex === index)),
        )}
      </div>
      <div className="button-container">
        <Button block type="primary" size="large" onClick={handleClick}>{t('getStarted')}</Button>
      </div>
    </div>
  );
};

export default Onboarding1;
