import React, { useEffect } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row } from 'antd';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from '../../context/StoreProvider';
import { ACTIONS } from '../../context/storeReducer';
import { getFsrCustomerCareNumber } from '../../utils/utility';
import './TopBar.scss';

interface TopBarProps {
  backVisibility?: boolean;
  logoVisibility?: boolean;
  callVisibility?: boolean;
}

const TopBar: React.FC<TopBarProps> = ({
  backVisibility = true,
  logoVisibility = true,
  callVisibility = true,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname === '/accounts' ||
      location.pathname === '/accounts/updates' ||
      location.pathname === '/profile' ||
      location.pathname === '/help' ||
      location.pathname === '/dashboard'
    ) {
      dispatch({
        type: ACTIONS.RESET_FILTERS,
      });
    }
  }, [location.pathname, dispatch]);

  return (
    <Row className="top-bar">
      <Col span={6} className="top-bar__left">
        {backVisibility && (
          <Button
            className="back-button"
            icon={<LeftOutlined />}
            onClick={() => navigate(-1)}
          >
            {t('back')}
          </Button>
        )}
      </Col>
      <Col span={12} className="top-bar__center">
        {logoVisibility && (
          <Image
            className="logo"
            preview={false}
            src="/icons/header-logo.png"
            onClick={() => navigate(-1)}
            alt="JJCC Logo"
          />
        )}
      </Col>
      <Col span={6} className="top-bar__right">
        {callVisibility && (
          <a href={`tel:${getFsrCustomerCareNumber()}`} className="call-button">
            <Image
              preview={false}
              className="call-icon"
              src="/icons/topbar-telephone.png"
              alt="Call Icon"
            />
          </a>
        )}
      </Col>
    </Row>
  );
};

export default TopBar;
