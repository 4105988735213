import { ThemeConfig } from 'antd';

const COLORS = {
	red: '#ca0d1b',
	black: '#212121',
	white: '#fff',
	coolGrey: '#f9fafb',
	warmGrey: '#f5f5f2',
	warmerGrey: '#ebebe6',
	grey100: '#f4f4f4',
	grey200: '#d8d8d8',
	grey300: '#888B8D',
	grey400: '#63666a',
	yellow: '#ffa800',
	green: '#0b8314',
	blue: '#00007d',
};

export const theme: ThemeConfig = {
	token: {
		colorPrimary: COLORS.red,
		fontSize: 16,
		colorText: COLORS.black,
	},
	components: {
		Button: {
			borderColorDisabled: COLORS.grey400,
			borderRadius: 0,
			borderRadiusSM: 0,
			borderRadiusLG: 0,
			colorBorder: COLORS.red,
			colorText: COLORS.red,
			colorPrimaryBorder: COLORS.red,
			defaultActiveBorderColor: COLORS.red,
			defaultHoverBorderColor: COLORS.red,
			fontWeight: 500,
			fontSizeLG: 16,
		},
	},
};
