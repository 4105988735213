import React, { useState, useEffect, ReactElement } from 'react';
import {
	Empty,
	Input,
	Divider,
	Typography,
	Space,
	Select,
	Row,
	Col,
	Button,
	Alert,
	message
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserCard, AppLayout, CircularProgressBar, DebounceSelect } from '../components';
import '../styles/UserDetail.scss';
import { BASE_TITLE } from '../utils/utility';
import { getUrl } from '../services/HttpService';
import useSearchUser from '../hooks/useSearchUser';
import {
	User,
	AccountNums,
	UserValue,
	FsrToken,
	Account
} from '../types/adminPortal';
import { COUNTRIES } from '../types/enums'

const canListAccounts = (
	loading: boolean,
	list: AccountNums[] | undefined,
	avgResponseTime: number,
	t: (key: string) => string,
): ReactElement => {
	return (
		<>
			{(!loading && list && list.length > 0) &&
				list?.map((account, index) => (
					<div key={index} className="account-item">
						<Typography.Text strong className="account-item-title">
							{account.account_name}
						</Typography.Text>
						<Typography.Text className="account-item-text">
							{account.account_number}
						</Typography.Text>
						<Divider className="divider" />
					</div>
				))}
			{loading && (
				<div className="loading-container">
					<CircularProgressBar
						percentage={avgResponseTime}
						status="exception"
						width="small"
					/>
				</div>
			)}
			{!loading && list?.length === 0 && (
				<Empty className="empty-message" description={t('nodata')} />
			)}
		</>
	);
};

const token: FsrToken | null = (() => {
	const storedToken = sessionStorage.getItem('fsrToken');
	if (storedToken === null) {
		return null;
	}
	try {
		return JSON.parse(storedToken) as FsrToken;
	} catch {
		return null;
	}
})();

export default function UserDetail(): ReactElement {
	const { t } = useTranslation();
	const { userWWID } = useParams();
	const [loading, setLoading] = useState<boolean>(true);
	const [userDetail, setUserDetail] = useState<User | null>(null);
	const [accountList, setAccountList] = useState<AccountNums[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [mustSearch, setMustSearch] = useState<boolean>(true);
	const [action, setAction] = useState<string>('');
	const avgResponseTime = 5;
	const [selectedAccounts] = useState<AccountNums[]>([]);
	const { data, isLoading, search, isError, error } = useSearchUser();
	const [value, setValue] = useState<UserValue[]>([]);
	const [role, setRole] = useState<string>();
	const [country, setCountry] = useState<string>();

	const optionsRole = [
		{ label: 'Admin', value: 'admin' },
		{ label: 'Team', value: 'team' },
	];

	useEffect(() => {
		document.title = BASE_TITLE + ' - Admin User Detail';
	}, []);


	useEffect(() => {
		if (mustSearch) {
			setMustSearch(false);
			pageSearch();
		}
	}, [mustSearch]);

	useEffect(() => {
		const selectedAccountsList = accountList.filter(account =>
			selectedAccounts.includes(account),
		);
		setAccountList(selectedAccountsList);
		setValue(
			selectedAccounts.map(account => ({
				label: `${account.account_number} - ${account.account_name}`,
				value: account.account_number,
			}))
		);
	}, [selectedAccounts]);

	const pageSearch = () => {
		setLoading(true);
		const searchParams = {
			fsr: true,
			validation: false,
			id: Number(userWWID),
		};
		search(searchParams);
	};

	// Set user search data
	useEffect(() => {
		if (data?.user?.length) {
			const dataUser = data?.user[0] as User
			setUserDetail(dataUser);
			setAccountList(dataUser?.accounts_num)
			setValue(
				dataUser?.accounts_num.map(account => ({
					label: `${account.account_number} - ${account.account_name}`,
					value: account.account_number,
				}))
			);
			setCountry(dataUser.fsr_country)
			setRole(dataUser.fsr_profile)
            setLoading(isLoading);
		}
	}, [data]);


	const doSearch = () => {
		const originalAccounts = userDetail?.accounts_num ? [...userDetail.accounts_num] : [];
		let filteredAccounts = originalAccounts;

		if (searchText) {
			const searchWords = searchText.toLowerCase().split(' ').filter(word => word);

			filteredAccounts = originalAccounts.filter(account => {
				const nameMatches = searchWords.every(word => account.account_name.toLowerCase().includes(word));
				const numberMatches = account.account_number.includes(searchText);

				return nameMatches || numberMatches;
			});
		}

		setAccountList(filteredAccounts);
	};


	useEffect(() => {
		doSearch()
	}, [searchText]);


	const handleEdit = () => {
		setAction('edit');
	};

	const handleStatus = (checked: boolean) => {
		setAction('status');
		setUserDetail({
			...userDetail,
			status: checked ? 'ACTIVE' : 'INACTIVE'
		} as User)
	};

	const handleCountryChange = (value: string) => {
		setCountry(value);
	};

	const handleRoleChange = (value: string) => {
		setRole(value)
	};

	const getAccounts = async (query: string): Promise<UserValue[]> => {
		const baseUrl = process.env.REACT_APP_BASE_URL;
		const api = process.env.REACT_APP_ADMIN_PORTAL_API;
		const URL = getUrl(`${baseUrl}${api}/accountsList`, {
			limit: 10,
			offset: 0,
			accNo: query,
		});
		return fetch(URL, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token?.access_token}`,
			}
		}
		)
			.then(res => res.json())
			.then(accounts =>
				accounts.map((account: Account) => ({
					label: `${account.account_number} - ${account.account_name}`,
					value: account.account_number,
				})),
			);
	};

	const handleSaveEdit = async () => {
		await editUser()
		setAction('');
	};

	const editUser = async (): Promise<void> => {
		setLoading(true);
		try {
			const baseUrl = process.env.REACT_APP_BASE_URL;
			const api = process.env.REACT_APP_ADMIN_PORTAL_API;
			const URL = `${baseUrl}${api}/users/${userDetail?.fsr_number}`;
			
			const response = await fetch(URL, {
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${token?.access_token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					profile: role,
					country: country?.toUpperCase(),
					accounts_num: value.map(acc => acc.value),
				}),
			});

			const responseData = await response.json();

			if (responseData.error) {
				throw new Error(responseData.error);
			}

			setMustSearch(true);
		} catch (error) {
			if (error instanceof Error) {
				const errorCode = (error as { error?: { errorCode?: string } }).error?.errorCode || 'UNKNOWN_ERROR';
				const errorMessage = t(`adminPortal.errors.${errorCode}`);
				message.error(errorMessage);
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<AppLayout topBarProps={{ backVisibility: true }}>
			<div className="container-user-detail">
				<div className="user-detail">
					<Typography.Title level={3} className="title-user  py-1 px-1">
						{t('adminPortal.userDetail.title')}
					</Typography.Title>
					{isError ?
						<Alert
							type="error"
							className='mx-1'
							message={t(`adminPortal.errors.${error}`)}
						/>
						:
						<>
							<UserCard
								loading={loading}
								user={userDetail}
								onEdit={handleEdit}
								onStatus={handleStatus}
							/>

							<div className="second-section-container">
								{action === 'edit' ? (
									<Row>
										<Col span={24}>
											<Space
												direction="vertical"
												size="middle"
												className="width-100"
											>
												<Space direction="vertical" className="width-100">
													<Typography.Text
														className="account-item-text"
														type="secondary"
													>
														{t('adminPortal.userDetail.selectedAccounts')}:
													</Typography.Text>
													<DebounceSelect
														fetchOptions={getAccounts}
														mode="multiple"
														size="large"
														value={value}
														initialOptions={value}
														placeholder="Search accounts"
														maxTagCount="responsive"
														onChange={(newValue: unknown) => {
															setValue(newValue as UserValue[]);
														}}
														className="width-100"
														disabled={data === null}
													/>
												</Space>
												<Space direction="vertical" className="width-100">
													<Typography.Text
														className="account-item-text"
														type="secondary"
													>
														{t('adminPortal.userNew.country')}:
													</Typography.Text>
													<Select
														size="large"
														value={country}
														onChange={handleCountryChange}
														options={COUNTRIES}
														disabled={data === null}
														className="width-100"
													/>
												</Space>
												<Space direction="vertical" className="width-100">
													<Typography.Text
														className="account-item-text"
														type="secondary"
													>
														{t('adminPortal.userDetail.userCard.role')}:
													</Typography.Text>
													<Select
														className="width-100"
														options={optionsRole}
														placeholder="Select accounts..."
														maxTagCount="responsive"
														value={role}
														onChange={handleRoleChange}
													/>
												</Space>
											</Space>
										</Col>
										<Col span={24} className="buttons-container">
											<Row gutter={10}>
												<Col span={12}>
													<Button
														type="default"
														block
														onClick={() => {
															setAction('');
														}}
													>
														{t('adminPortal.userDetail.button.cancel')}
													</Button>
												</Col>
												<Col span={12}>
													<Button
														type="primary"
														block
														onClick={handleSaveEdit}
													>
														{t('adminPortal.userDetail.button.save')}
													</Button>
												</Col>
											</Row>
										</Col>
									</Row>
								) : (
									<>
										<Typography.Title level={5} className="subtitle">
											{t('adminPortal.userDetail.subtitle')} ({userDetail?.accounts_num?.length})
										</Typography.Title>
										<Input
											className="account__list__search"
											allowClear
											size="small"
											prefix={
												<img
													src="/icons/search-icon.png"
													onClick={() => {
														setSearchText('');
													}}
													style={{ cursor: 'pointer' }}
													className="search-image"
													alt="search"
												/>
											}
											placeholder={t('adminPortal.userDetail.search')}
											onPressEnter={doSearch}
											onChange={e => {
												setSearchText(e.target.value);
											}}
											onFocus={() => {
												document
													.getElementsByClassName('portraitMode')[0]
													.classList.add('search-active');
												document
													.getElementsByClassName('landscapeMode')[0]
													.classList.add('hidden');
											}}
											onBlur={() => {
												document
													.getElementsByClassName('portraitMode')[0]
													.classList.remove('search-active');
												document
													.getElementsByClassName('landscapeMode')[0]
													.classList.remove('hidden');
											}}
										/>
										<div
											className="account__list__container"
										>
											{canListAccounts(isLoading, accountList, avgResponseTime, t)}
										</div>
									</>
								)}
							</div>
						</>
					}
				</div>
			</div>
		</AppLayout>
	);
}
