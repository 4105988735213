import { useState } from 'react';
import { getUrl } from '../services/HttpService';
import { FsrToken, SearchUserRequestState } from '../types/adminPortal';

export type SearchUserParams = {
	email?: string | undefined;
	id?: number | undefined;
	fsr?: boolean | undefined;
	validation?: boolean | undefined;
};

const initialState = {
	data: null,
	isLoading: false,
	isSuccess: false,
	isError: false,
	error: '',
};

export default function useSearchUser<T>() {
	const [state, setState] = useState<SearchUserRequestState<T>>(initialState);

	const baseUrl = process.env.REACT_APP_BASE_URL;
	const api = process.env.REACT_APP_ADMIN_PORTAL_API;
	const URL = `${baseUrl}${api}/users`;

	const runSearch = async (searchParams: SearchUserParams) => {
		if (!searchParams) return;
		setState(state => ({ ...state, isLoading: true }));
		const url = getUrl(URL, { limit: 10, offset: 0, ...searchParams });
		const token: FsrToken | null = (() => {
			const storedToken = sessionStorage.getItem('fsrToken');
			if (storedToken === null) {
				return null;
			}
			try {
				return JSON.parse(storedToken) as FsrToken;
			} catch {
				return null;
			}
		})();
		try {
			const response = await fetch(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.access_token}`,
				},
			});
			const data = await response.json();
			if (data?.error) {
				setState({
					data: null,
					isLoading: false,
					isSuccess: false,
					isError: true,
					error: data?.error?.errorCode,
				});
				return;
			}
			setState({
				data,
				isLoading: false,
				isSuccess: true,
				isError: false,
				error: '',
			});
		} catch (error) {
			setState(state => ({
				...state,
				isLoading: false,
				isError: true,
				error: error instanceof Error ? error.message : '',
			}));
		} finally {
			setState(state => ({ ...state, isLoading: false }));
		}
	};
	const onClear = () => {
		setState(initialState);
	};

	return { ...state, search: runSearch, clear: onClear };
}
