import { Col, Empty, Row, Typography } from 'antd';
import React, {
	useEffect,
	useState,
	ChangeEvent,
	KeyboardEvent,
	UIEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	AppLayout,
	AppSearch,
	CircularProgressBar,
	Filters,
	Order,
} from '../components';
import { useDispatch, useStore } from '../context/StoreProvider';
import { ACTIONS } from '../context/storeReducer';
import useFetchOrders from '../hooks/useFetchOrders';
import { FSR_ORDER_STATUS } from '../types/enums';
import { BASE_TITLE } from '../utils/utility';
import './../styles/OrderIndex.scss';
import { handleGeneralScroll } from '../utils/scrollHelpers';
import { OrderType, StoreType } from '../types/interface';

const LIMIT = 10;

export default function OrderIndex() {
	const { ordersFilters, orders } = useStore() as StoreType;
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState('');
	const { t } = useTranslation();
	const {
		data,
		meta,
		fetchData,
		setHasMore,
		isLoading,
		setResetOrders,
		setData,
	} = useFetchOrders();
	const [activeFilters, setActiveFilters] = useState(ordersFilters);
	const [refetch, setRefetch] = useState(false);
	const isAll = ordersFilters?.status === FSR_ORDER_STATUS.ALL;

	useEffect(() => {
		document.title = BASE_TITLE + ' - Order Index';
		fetchData({
			limit: LIMIT,
			offset: orders?.page,
			sortType: ordersFilters?.sort,
			accountInfo: 'true',
			...(!isAll ? { orderStatus: ordersFilters?.status } : {}),
			...(searchText ? { searchParam: searchText } : {}),
			...(ordersFilters?.date ? { creationDate: ordersFilters?.date } : {}),
			...(ordersFilters?.type ? { orderType: ordersFilters?.type } : {}),
		});
	}, []);

	useEffect(() => {
		const filtersWasUpdated =
			JSON.stringify(activeFilters) !== JSON.stringify(ordersFilters);
		if (filtersWasUpdated) {
			setData([]);
			dispatch({
				type: ACTIONS.UPDATE_ORDERS,
				payload: { name: 'page', value: 0 },
			});
			setRefetch(true);
			setActiveFilters(ordersFilters);
		}
	}, [ordersFilters]);

	useEffect(() => {
		if (refetch) {
			fetchData({
				limit: LIMIT,
				offset: orders?.page,
				sortType: ordersFilters?.sort,
				accountInfo: 'true',
				...(!isAll ? { orderStatus: ordersFilters?.status } : {}),
				...(searchText ? { searchParam: searchText } : {}),
				...(ordersFilters?.date ? { creationDate: ordersFilters?.date } : {}),
				...(ordersFilters?.type ? { orderType: ordersFilters?.type } : {}),
			});
			setRefetch(false);
		}
	}, [refetch, searchText]);

	const initSearch = () => {
		(document.activeElement as HTMLElement).blur();
		setData([]);
		dispatch({
			type: ACTIONS.UPDATE_ORDERS,
			payload: {
				name: 'page',
				value: 0,
			},
		});
		setRefetch(true);
		window.focus();
		(document.activeElement as HTMLElement).blur();
	};

	const setOrder = (order: OrderType, key: string): JSX.Element => (
		<Order
			key={key}
			order={order}
			loading={isLoading && data.length < 1}
			withNavigation={true}
			accountId={order.cacNumber}
			allOrders={true}
		/>
	);

	const handleScroll = (e: UIEvent<HTMLDivElement>) => {
		const totalItems = meta[activeFilters.status ?? 'ALL'] || meta['ALL'];
		const shouldLoadMore = handleGeneralScroll({
			event: e,
			currentDataLength: data.length,
			isLoading: Number(isLoading),
			totalItemCount: totalItems,
			currentPage: String(orders?.page),
			itemsPerPage: LIMIT,
		});
		if (shouldLoadMore) {
			setHasMore(true);
			setResetOrders(false);
			dispatch({
				type: ACTIONS.UPDATE_ORDERS,
				payload: {
					name: 'page',
					value: orders.page + 1,
				},
			});
			setRefetch(true);
		}
	};

	const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			initSearch();
		}
	};

	return (
		<AppLayout>
			<div className="common-page-container">
				<Row justify="space-around" align="middle" className="order-index">
					<Col span={23} className="col-container">
						{/* screen title */}
						<Row
							justify="space-around"
							align="middle"
							className="main-topic-height"
						>
							<Col span={24} className="header-title">
								<Typography.Title level={5} className="page-header-text">
									{t('orders')}
								</Typography.Title>
							</Col>
						</Row>
						{/* Search input */}
						<Row className="search-height">
							<Col span={24}>
								<AppSearch
									placeholder={t('orderSearch')}
									onChange={(event: ChangeEvent<HTMLInputElement>) => {
										setSearchText(event.target.value);
										if (event.target.value === '') {
											initSearch();
										}
									}}
									onPressSearch={initSearch}
									onPressEnter={initSearch}
									onKeyPress={handleKeyPress}
								/>
							</Col>
						</Row>
						{/* Filters */}
						<Row align="middle">
							<Col span={24}>
								<Filters meta={meta as Record<string, number>} />
							</Col>
						</Row>
						{/* Status filter title */}
						<Row
							justify="space-around"
							align="middle"
							className="sub-topic-height"
						>
							<Col span={24}>
								<Typography.Title className="filter-name-text" level={5}>
									{t(ordersFilters?.status || '')}
								</Typography.Title>
							</Col>
						</Row>
						{/* Orders list container */}
						<Row
							justify="space-around"
							align="middle"
							className="order-container"
							onScroll={handleScroll}
						>
							{data.map(order => setOrder(order, order.orderNum))}
							{data.length === 0 && !isLoading && (
								<Empty description={t('nodata')} />
							)}
							{isLoading ? (
								<CircularProgressBar
									percentage={10}
									status="exception"
									width="small"
								/>
							) : null}
						</Row>
					</Col>
				</Row>
			</div>
		</AppLayout>
	);
}
