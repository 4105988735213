import React, { ReactElement, useEffect } from 'react';
import { Typography, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DESKTOP_ADHERENCE } from '../types/enums';
import { AppLayout } from '../components';
import { BASE_TITLE } from '../utils/utility';

export default function OKRs(): ReactElement {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = BASE_TITLE + ' - Admin OKRs';
    }, []);

    return (
        <AppLayout topBarProps={{ backVisibility: true }}>
            <Space direction="vertical" className="width-100 px-1 py-1" size="middle">
                <div>
                    <Typography.Title level={3} className="title-user">{t('adminPortal.okrs.title')}</Typography.Title>
                </div>
                <Space direction="vertical" className="width-100" size="large">
                    <Button
                        size="large"
                        type="primary"
                        block
                        onClick={() => {
                            window.location.href = DESKTOP_ADHERENCE;
                        }}
                    >
                        {t('adminPortal.okrs.desktopAdherence')}
                    </Button>

                    <Button
                        size="large"
                        type="primary"
                        block
                        onClick={() => {
                            
                        }}
                    >
                        {t('adminPortal.okrs.adherence')}
                    </Button>

                    <Button
                        size="large"
                        type="primary"
                        block
                        onClick={() => {

                        }}
                    >
                        {t('adminPortal.okrs.frequency')}
                    </Button>
                </Space>

            </Space>
        </AppLayout>
    );
}
