let isDragging = false,
	startPos = 0,
	currentTranslate = 0,
	prevTranslate = 0,
	animationID = 0,
	currentIndex = 0;

export function touchStart(index) {
	return function (event) {
		currentIndex = index;
		startPos = getPositionX(event);
		isDragging = true;
		animationID = requestAnimationFrame(animation);
	};
}

export function touchEnd(
	navigate,
	endpoint,
	endpoint1,
	navigateWithoutEnpoints = false,
) {
	return function () {
		isDragging = false;
		cancelAnimationFrame(animationID);

		const movedBy = currentTranslate - prevTranslate;

		if (movedBy < -100 && currentIndex < 7) {
			if (navigateWithoutEnpoints) {
				navigate(1);
			} else {
				navigate(`/${endpoint}`);
			}
		}
		if (movedBy > 100) {
			if (navigateWithoutEnpoints) {
				navigate(-1);
			} else {
				navigate(`/${endpoint1}`);
			}
		}

		setPositionByIndex();
	};
}

export function touchMove(event) {
	if (isDragging) {
		const currentPosition = getPositionX(event);
		currentTranslate = prevTranslate + currentPosition - startPos;
	}
}

function getPositionX(event) {
	return event.type.includes('mouse') ? event.pageX : event.touches[0].clientX;
}

function animation() {
	if (isDragging) requestAnimationFrame(animation);
}

function setPositionByIndex() {
	currentTranslate = currentIndex * -window.innerWidth;
	prevTranslate = currentTranslate;
}
