import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getOrders } from '../services/accountOrders';
import { INITIAL_ORDERS_META } from '../types/enums';
import { OrderApiResponse, Params, FetchOrdersResult } from '../types/api';
import { OrderType, AccountDetails, MetaType } from '../types/interface';

function useFetchOrders(): FetchOrdersResult {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState<OrderType[]>([]);
    const [accountOrders, setAccountOrders] = useState<OrderType[]>([]);
    const [accountDetails, setAccountDetails] = useState<AccountDetails>({
        accountName: t('loading'),
        accountId: t('loading'),
        accountAddress: t('loading'),
    });
    const [error, setError] = useState<Error | null>(null);
    const [hasMore, setHasMore] = useState(false);
    const [resetOrders, setResetOrders] = useState(false);
    const [meta, setMeta] = useState<MetaType>(INITIAL_ORDERS_META);

    const fetchData = async (params: Params) => {
        setIsLoading(true);
        const hasAccountNumber = Object.prototype.hasOwnProperty.call(params, 'accNo');
        try {
            const response: OrderApiResponse[] = await getOrders(params);
            const ordersSummary = response.shift()?.meta?.order_summary;
            if (ordersSummary) {
                setMeta(ordersSummary);
            } else {
                setMeta(INITIAL_ORDERS_META);
            }

            if (hasAccountNumber) {
                const account = response[0];
                setAccountDetails({
                    accountName: account.cac_account_name || t('loading'),
                    accountId: account.cac_account_number || t('loading'),
                    accountAddress: account.cac_account_address || t('loading'),
                });
                const accOrders = account.account_orders?.map(order => ({
                    orderNum: order.order_number.split('-')[0],
                    accountName: order.shipto_cus_name,
                    accountId: order.cus_shipto_num,
                    poNumber: order.po_number,
                    requestedDate: order.order_placement_date,
                    totalAmount: Number(order.total_price) || 0,
                    currencyCode: order.currency,
                    updatedDate: order.order_last_update,
                    prcntComplete: Number(order.ordr_prcnt_complete) || 0,
                    orderStatus: order.order_status,
                    orderRecordId: order.order_number,
                    orderType: order.order_type,
                    type: order.type_ot,
                    description: order.description_ot,
                })) || [];
                if (hasMore) {
                    setAccountOrders(prevOrders => [...prevOrders, ...accOrders]);
                    setHasMore(false);
                } else {
                    setAccountOrders(accOrders);
                }
            } else {
                const orders = response.map(order => ({
                    orderNum: order.order_number?.split('-')[0] || '',
                    accountName: order.shipto_cus_name || '',
                    accountId: order.cus_shipto_num || '',
                    cacNumber: order.cus_cac_num || '',
                    poNumber: order.po_number || '',
                    requestedDate: order.order_placement_date || '',
                    totalAmount: Number(order.total_price) || 0,
                    currencyCode: order.currency || '',
                    updatedDate: order.updated_date_time || '',
                    prcntComplete: Number(order.ordr_prcnt_complete) || 0,
                    orderStatus: order.order_status || '',
                    orderRecordId: order.order_number || '',
                    orderType: order.order_type || '',
                    type: order.type_ot || '',
                    description: order.description_ot || '',
                }));

                if (hasMore) {
                    setData(prevData => [...prevData, ...orders]);
                    setHasMore(false);
                } else {
                    setData(orders);
                }
            }
        } catch (err) {
            setHasError(true);
            setError(err instanceof Error ? err : new Error('Unknown error'));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        hasError,
        data,
        setData,
        meta,
        error,
        fetchData,
        hasMore,
        setHasMore,
        resetOrders,
        setResetOrders,
        accountOrders,
        setAccountOrders,
        accountDetails,
        setAccountDetails,
    };
}

export default useFetchOrders;
