import React, { useRef } from 'react';
import { Col, DatePicker, Row, Select } from 'antd';
import type { PickerRef } from 'rc-picker';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { useDispatch, useStore } from '../../context/StoreProvider';
import { ACTIONS } from '../../context/storeReducer';
import { FSR_ORDER_STATUS, ORDERS_FILTERS } from '../../types/enums';
import { OrderFilter } from '../index';
import './Filters.scss';

interface FiltersProps {
  meta: Record<string, number>;
}

interface OrdersFilters {
  sort: number;
  status: typeof FSR_ORDER_STATUS[keyof typeof FSR_ORDER_STATUS];
  date: string | null;
  type: string;
}

const Filters: React.FC<FiltersProps> = ({ meta }) => {
  const { ordersFilters } = useStore() as { ordersFilters: OrdersFilters };
  const dispatch = useDispatch();
  const datePickerRef = useRef<PickerRef>(null);

  const handleSortChange = (value: number) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTERS,
      payload: {
        name: ORDERS_FILTERS.SORT,
        value,
      },
    });
  };

  const handleStatusChange = (value: string) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTERS,
      payload: {
        name: ORDERS_FILTERS.STATUS,
        value,
      },
    });
  };

  const handleDateChange = (date: Dayjs | null) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    if (datePickerRef.current) {
      datePickerRef.current.blur();
    }

    dispatch({
      type: ACTIONS.UPDATE_FILTERS,
      payload: {
        name: ORDERS_FILTERS.DATE,
        value: formattedDate,
      },
    });
  };

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs().endOf('day');
  };

  const handleTypeChange = (value: string) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTERS,
      payload: {
        name: ORDERS_FILTERS.TYPE,
        value,
      },
    });
  };

  const setCountryTypesOptions = () => {
    const country = localStorage.getItem('fsr_country');
    const regexCO = /^colombia$/i;
    const regexMX = /^mexico$/i;

    if (regexCO.test(country || '')) {
      return ORDERS_FILTERS.TYPE_OPTIONS_CO.map((option: string) => ({
        value: option,
        label: t(`descriptions.${option}`),
      }));
    } else if (regexMX.test(country || '')) {
      return ORDERS_FILTERS.TYPE_OPTIONS_MX.map((option: string) => ({
        value: option,
        label: t(`descriptions.${option}`),
      }));
    } else {
      return ORDERS_FILTERS.TYPE_OPTIONS_BR.map((option: string) => ({
        value: option,
        label: t(`descriptions.${option}`),
      }));
    }
  };

  return (
    <>
      <Row
        gutter={16}
        align="middle"
        className="filter-buttons-height"
        justify="space-between">
        <Col span={6}>
          <OrderFilter
            onClick={() => {
              handleStatusChange(FSR_ORDER_STATUS.ALL);
            }}
            text={meta[FSR_ORDER_STATUS.ALL]}
            filter={FSR_ORDER_STATUS.ALL}
          />
        </Col>
        <Col span={6}>
          <OrderFilter
            onClick={() => {
              handleStatusChange(FSR_ORDER_STATUS.NEEDS_ATTENTION);
            }}
            text={meta[FSR_ORDER_STATUS.NEEDS_ATTENTION]}
            filter={FSR_ORDER_STATUS.NEEDS_ATTENTION}
          />
        </Col>
        <Col span={6}>
          <OrderFilter
            onClick={() => {
              handleStatusChange(FSR_ORDER_STATUS.IN_PROGRESS);
            }}
            text={meta[FSR_ORDER_STATUS.IN_PROGRESS]}
            filter={FSR_ORDER_STATUS.IN_PROGRESS}
          />
        </Col>
        <Col span={6}>
          <OrderFilter
            onClick={() => {
              handleStatusChange(FSR_ORDER_STATUS.COMPLETED);
            }}
            text={meta[FSR_ORDER_STATUS.COMPLETED]}
            filter={FSR_ORDER_STATUS.COMPLETED}
          />
        </Col>
      </Row>
      <Row gutter={16} justify="space-between" align="middle">
        <Col span={8} className="filters__sort">
          <Select
            className="sort"
            options={[
              { value: 1, label: t('filters.options.sortOldest') },
              { value: 3, label: t('filters.options.sortNewest') },
              { value: 2, label: t('filters.options.sortUpdated') },
            ]}
            value={ordersFilters?.sort}
            placeholder={t('filters.labels.sort')}
            onChange={handleSortChange}
            popupMatchSelectWidth={false}
            disabled={ordersFilters?.date !== null}
          />
        </Col>
        <Col span={8} className="filters__date">
          <DatePicker
            onChange={handleDateChange}
            allowClear
            format={'DD/MM/YYYY'}
            placeholder={t('filters.labels.date')}
            disabledDate={disabledDate}
            ref={datePickerRef}
            value={ordersFilters?.date ? dayjs(ordersFilters.date) : null}
            popupClassName="popupContainer"
          />
        </Col>
        <Col span={8} className="filters__type">
          <Select
            className="type"
            options={setCountryTypesOptions()}
            defaultValue={ordersFilters?.type}
            placeholder={t('filters.labels.type')}
            popupMatchSelectWidth={false}
            disabled={ordersFilters?.date !== null}
            allowClear
            onChange={handleTypeChange}
          />
        </Col>
      </Row>
    </>
  );
}

export default Filters;
