import { useState, useEffect, ReactElement } from 'react';
import {
	Input,
	Typography,
	Space,
	Select,
	Row,
	Col,
	Button,
	Card,
	Alert,
	message,
	AutoComplete,
	AutoCompleteProps,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DebounceSelect, AppLayout } from '../components';
import '../styles/UserNew.scss';
import { BASE_TITLE } from '../utils/utility';
import useSearchUser from '../hooks/useSearchUser';
import { getUrl } from '../services/HttpService';
import { useFetch } from '../hooks/useFetch/useFetch';

import {
	NewUserBody,
	Account,
	UserValue,
	FsrToken,
	NewUserResponse,
	UserValidation,
} from '../types/adminPortal';
import { SearchOutlined } from '@ant-design/icons';

import { COUNTRIES } from '../types/enums'

export default function UserNew(): ReactElement {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [userDetail, setUserDetail] = useState<UserValidation | null>(null);
	const [profile, setProfile] = useState<string | null>(null);
	const [country, setCountry] = useState<string | null>(null);
	const [value, setValue] = useState<UserValue[]>([]);
	const [messageApi, contextHolder] = message.useMessage();
	const [options, setOptions] = useState<AutoCompleteProps['options']>([]);

	const optionsRole = [
		{ label: 'Admin', value: 'admin' },
		{ label: 'Team', value: 'team' },
	];

	const {
		data,
		isLoading: validationIsLoading,
		isError,
		error,
		search: validate,
		clear: clearValidation,
	} = useSearchUser<UserValidation>();

	// Fetch users search
	const {
		data: users,
		isError: fetchUsersIsError,
		search: fecthUsers,
		clear: clearFetchUsers,
	} = useSearchUser<UserValidation>();

	// Fetch new user
	const { state, run: createNewUser } = useFetch<NewUserResponse>();

	const getAccounts = async (query: string): Promise<UserValue[]> => {
		const baseUrl = process.env.REACT_APP_BASE_URL;
		const api = process.env.REACT_APP_ADMIN_PORTAL_API;
		const token: FsrToken | null = (() => {
			const storedToken = sessionStorage.getItem('fsrToken');
			if (storedToken === null) {
				return null;
			}
			try {
				return JSON.parse(storedToken) as FsrToken;
			} catch {
				return null;
			}
		})();
		const URL = getUrl(`${baseUrl}${api}/accountsList`, {
			limit: 10,
			offset: 0,
			accNo: query,
		});
		return fetch(URL, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token?.access_token}`,
			}
		}
		)
			.then(res => res.json())
			.then(accounts =>
				accounts.map((account: Account) => ({
					label: `${account.account_number} - ${account.account_name}`,
					value: account.account_number,
				})),
			);
	};

	useEffect(() => {
		document.title = BASE_TITLE + ' - Admin User New';
	}, []);

	const onClear = () => {
		clearValidation();
		clearFetchUsers();
		setCountry(null);
		setProfile('');
		setValue([]);
		setOptions([]);
	};

	const searchUser = (value: string) => {
		if (value !== '') {
			const isNumber = /^\d+$/.test(value);
			const searchParams = {
				fsr: false,
				validation: false,
				...(isNumber ? { wwid: Number(value) } : { email: `${value}` }),
			};
			fecthUsers(searchParams);
		}
	};

	const validateUser = (value: string) => {
		const isNumber = /^\d+$/.test(value);
		const searchParams = {
			fsr: true,
			validation: true,
			...(isNumber ? { wwid: Number(value) } : { email: `${value}` }),
		};
		validate(searchParams);
	};

	// Set user search data
	useEffect(() => {
		if (data?.user) {
			setUserDetail(data?.user[0]);
		}
	}, [data]);

	// Set users serach data
	useEffect(() => {
		if (users?.user) {
			setOptions(
				users?.user
					? users?.user.map((user: UserValidation) => {
							return {
								value: user.email,
								label: (
									<div key={user.wwid}>
										<span>{user.email}</span>
									</div>
								),
							};
						})
					: [],
			);
		}
	}, [users]);

	// Validate if all inputs has information to create a new user
	const isFormValid = (): boolean => {
		return (
			userDetail !== null &&
			country !== null &&
			profile !== null &&
			value.length > 0
		);
	};

	const handleCreateUser = (newUser: NewUserBody) => {
		const url = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN_PORTAL_API}/users`;

		const token: FsrToken | null = (() => {
			const storedToken = sessionStorage.getItem('fsrToken');
			if (storedToken === null) {
				return null;
			}
			try {
				return JSON.parse(storedToken) as FsrToken;
			} catch {
				return null;
			}
		})();
		const options = {
			method: 'POST',
			body: JSON.stringify(newUser),
			headers: {
				Authorization: `Bearer ${token?.access_token}`,
				'Content-Type': 'application/json',
			},
		};
		createNewUser(url, options, {});
	};

	// Handle new user creation message
	useEffect(() => {
		if (state.error) {
			messageApi.open({
				type: 'error',
				content: t('adminPortal.userNew.creationMessage.error'),
				duration: 3,
			});
			return;
		}
		if (state?.data?.status === 201) {
			messageApi
				.open({
					type: 'success',
					content: t('adminPortal.userNew.creationMessage.success'),
					duration: 3,
				})
				.then(() => navigate('/profile'));
		}
	}, [state.data, state.error]);

	return (
		<AppLayout topBarProps={{ backVisibility: true }}>
			{contextHolder}
			<div className="container-user-new">
				<div className="user-detail px-1">
					<Typography.Title level={4} className="title-user py-1">
						{t('adminPortal.userNew.title')}
					</Typography.Title>
					<Space direction="vertical" className="width-100" size="large">
						<Space direction="vertical" className="width-100">
							<Typography.Text className="account-item-text" type="secondary">
								{t('adminPortal.userNew.emailOrWWID')}:
							</Typography.Text>
							<AutoComplete
								style={{ width: '100%' }}
								popupMatchSelectWidth={true}
								onSelect={validateUser}
								onSearch={searchUser}
								options={options}
								status={fetchUsersIsError ? 'error' : undefined}
								allowClear
								onClear={onClear}
							>
								<Input size="large" addonBefore={<SearchOutlined />} />
							</AutoComplete>
						</Space>
						{isError && (
							<Alert type="error" message={t(`adminPortal.errors.${error}`)} />
						)}
						{!isError && !validationIsLoading && data && (
							<Card className="user-card">
								<Row>
									<Col span={24}>
										<Space.Compact direction="vertical" size="small">
											<Space>
												<Typography.Text className="text-items" strong>
													{t('adminPortal.userNew.userCard.name')}:
												</Typography.Text>
												<Typography.Text className="text-items">
													{userDetail?.name}
												</Typography.Text>
											</Space>
											<Space>
												<Typography.Text className="text-items" strong>
													{t('adminPortal.userNew.userCard.email')}:
												</Typography.Text>
												<Typography.Text className="text-items">
													{userDetail?.email}
												</Typography.Text>
											</Space>
											<Space>
												<Typography.Text className="text-items" strong>
													{t('adminPortal.userNew.userCard.WWID')}:
												</Typography.Text>
												<Typography.Text className="text-items">
													{userDetail?.wwid}
												</Typography.Text>
											</Space>
										</Space.Compact>
									</Col>
								</Row>
							</Card>
						)}

						<div className="second-section-container">
							<Row>
								<Col span={24}>
									<Space
										direction="vertical"
										size="middle"
										className="width-100"
									>
										<Space direction="vertical" className="width-100">
											<Typography.Text
												className="account-item-text"
												type="secondary"
											>
												{t('adminPortal.userNew.country')}:
											</Typography.Text>
											<Select
												size="large"
												value={country}
												onChange={setCountry}
												options={COUNTRIES}
												disabled={data === null}
												className="width-100"
											/>
										</Space>
										<Space direction="vertical" className="width-100">
											<Typography.Text
												className="account-item-text"
												type="secondary"
											>
												{t('adminPortal.userNew.role')}:
											</Typography.Text>
											<Select
												className="width-100"
												options={optionsRole}
												placeholder={t('adminPortal.userNew.selectedOption')}
												maxTagCount="responsive"
												value={profile}
												onChange={setProfile}
												size="large"
												disabled={data === null}
											/>
										</Space>
										<Space direction="vertical" className="width-100">
											<Typography.Text
												className="account-item-text"
												type="secondary"
											>
												{t('adminPortal.userNew.selectedAccounts')}:
											</Typography.Text>
											<DebounceSelect
												fetchOptions={getAccounts}
												mode="multiple"
												size="large"
												value={value}
												placeholder="Search accounts"
												maxTagCount="responsive"
												onChange={(newValue: unknown) => {
													setValue(newValue as UserValue[]);
												}}
												className="width-100"
												disabled={data === null}
											/>
										</Space>
									</Space>
								</Col>
								<Col span={24} className="buttons-container">
									{state?.error ? (
										<Alert
											type="error"
											message={t(`adminPortal.errors.${state?.error}`)}
											style={{ marginBottom: 10 }}
											closable
											showIcon
										/>
									) : state?.data?.status === 201 ? (
										<Alert
											type="success"
											message={t('adminPortal.userNew.creationMessage.success')}
											showIcon
											style={{ marginBottom: 10 }}
										/>
									) : null}
									<Row gutter={10}>
										<Col span={12}>
											<Button
												type="default"
												size="large"
												block
												onClick={() => {
													navigate(-1);
												}}
											>
												{t('adminPortal.userNew.button.cancel')}
											</Button>
										</Col>
										<Col span={12}>
											<Button
												type="primary"
												size="large"
												block
												onClick={() =>
													handleCreateUser({
														email: userDetail?.email,
														fsr_number: Number(userDetail?.wwid),
														fsr_name: userDetail?.name,
														fsr_country: country?.toUpperCase(),
														fsr_profile: profile,
														account_nums: value.map(acc => acc.value),
													})
												}
												loading={state?.isLoading}
												disabled={!isFormValid()}
											>
												{t('adminPortal.userNew.button.create')}
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Space>
				</div>
			</div>
		</AppLayout>
	);
}
