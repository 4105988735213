import { useTranslation } from 'react-i18next';

type AppContainerProps = {
  children: React.ReactNode,
}
export default function AppContainer({ children }: AppContainerProps) {
  const { t } = useTranslation();
  return (
    <>
      <main className="portraitMode">{children}</main>
      <div className="landscapeMode">
        <div className="center">
          <p>{t('landscapeMode')}</p>
        </div>
      </div>
    </>
  );
}
