import { FSR_ORDER_STATUS } from '../types/enums';

export const ACTIONS = {
	UPDATE_FILTERS: 'UPDATE_FILTERS',
	RESET_FILTERS: 'RESET_FILTERS',
	UPDATE_ORDERS: 'UPDATE_ORDERS',
};

/**
 * The initial state of the application store.
 *
 * @type {{
 *   ordersFilters: {
 *     status: string,
 *     sort: number,
 *     date: Date|null,
 *     type: string|null
 *   },
 *   orders: {
 *     page: number
 *   }
 * }}
 */
const initialStore = {
	ordersFilters: {
		/**
		 * The status filter for orders (e.g., 'ALL', 'COMPLETED', 'NEEDS_ATTENTION', 'IN_PROGRESS').
		 *
		 * @type {string}
		 */
		status: FSR_ORDER_STATUS.ALL,

		/**
		 * The sorting option for orders (e.g., 1 for ascending created date, 2 for descending created date, 3 for default).
		 *
		 * @type {number}
		 */
		sort: 3,

		/**
		 * The date filter for orders (null if not set).
		 *
		 * @type {Date|null}
		 */
		date: null,

		/**
		 * The type filter for orders (null if not set).
		 *
		 * @type {string|null}
		 */
		type: null,
	},
	orders: {
		/**
		 * The current page number for orders.
		 *
		 * @type {number}
		 */
		page: 0,
	},
};

const storeReducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.UPDATE_FILTERS:
			return {
				...state,
				ordersFilters: {
					...state.ordersFilters,
					[action.payload.name]: action.payload.value,
				},
			};
		case ACTIONS.RESET_FILTERS:
			return {
				...state,
				ordersFilters: {
					...initialStore.ordersFilters,
				},
			};
		case ACTIONS.UPDATE_ORDERS:
			return {
				...state,
				orders: {
					...state.orders,
					[action.payload.name]: action.payload.value,
				},
			};
		default:
			return state;
	}
};

export { initialStore };
export default storeReducer;
