import { Col, Empty, Image, Input, Row, Typography } from 'antd';
import { t } from 'i18next';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	AppLayout,
	Breadcrumb,
	CircularProgressBar,
	LineFilterButton,
	LineItem,
	Order,
} from '../components';
import { getFilterButtonText } from '../components/LineFilterButton';
import {
	getProductImage,
	getProductImagesToken,
} from '../services/ProductImages';
import { getOrders } from '../services/accountOrders';
import { FilterType, ORDER_API_STATUS_TEXT } from '../types/enums';
import { OrderApiResponse, SearchParams, OrderItem } from '../types/api';
import { touchEnd, touchMove, touchStart } from '../utils/touchfunctionality';
import { ImageData, LineItemType, OrderDetails } from '../types/interface';
import { BASE_TITLE } from '../utils/utility';
import './../styles/OrderHead.scss';
import { handleGeneralScroll } from '../utils/scrollHelpers';
import { debounce } from '../utils/debounce';

const limit = 10;

// returns the search params related to a single order
const getSearchParams = (
	activeFilter: FilterType,
	searchText: string,
	orderId: string,
	pageNumber: number,
): SearchParams => {
	const searchParams: SearchParams = {
		offset: pageNumber,
		limit: limit,
		oId: orderId,
		orderLines: 'true',
	};

	// if the search text is empty remove the searchParam from the search query
	if (searchText !== '') {
		searchParams.searchParam = searchText;
	}

	// if the searching order line item status is not ALL, add the orderLineStatus to the search query
	if (activeFilter !== ORDER_API_STATUS_TEXT.ALL && activeFilter !== 'ALL') {
		searchParams.orderLineStatus = activeFilter;
	}

	return searchParams;
};

// check if the API result contains line items before setting
const canSetLineItems = (
	items: LineItemType[],
	setLineItems: React.Dispatch<React.SetStateAction<LineItemType[]>>,
	resetAccountList: boolean,
) => {
	if (items.length > 0) {
		setLineItems(prev => (resetAccountList ? items : prev.concat(items)));
	} else if (resetAccountList) {
		setLineItems([]);
	}
};

// check if active API call is happening and the screen is not initialized to set all line items status to loading
const isLineItemLoading = (loading: boolean, init: boolean) => loading && !init;

// return the circular loading icon if the screen is in loading status
const getLoading = (loading: boolean) =>
	loading ? (
		<CircularProgressBar percentage={10} status="exception" width="default" />
	) : null;

// returns empty icon if the API is not loading anything and length of the line items are 0
const getEmpty = (length: number, loading: boolean) =>
	length === 0 && !loading ? <Empty description={t('nodata')} /> : null;

// call product image API to load image from product code
const loadImage = (
	productCode: string,
	setProductImages: React.Dispatch<
		React.SetStateAction<Record<string, ImageData>>
	>,
) => {
	getProductImage(productCode)
		.then(res => {
			if (res && res?.images) {
				const prodImage = res?.images.filter(
					(image: { imageSize: string; fullUrl: string }) =>
						image.imageSize === 'original',
				);
				if (prodImage.length > 0) {
					setProductImages(prev => ({
						...prev,
						[productCode]: {
							imageUrl: prodImage[0].fullUrl,
							imageAlt: `Image of ${res?.shortDescription}`,
						},
					}));
				}
			} else {
				console.error(`No images found for product code: ${productCode}`);
			}
		})
		.catch(error => {
			console.error(
				`Error fetching product image for code: ${productCode}`,
				error,
			);
		});
};

const handleEmptyOrArray = (value: string[] | string): string => {
	if (Array.isArray(value)) {
		return value.join(', ');
	} else if (typeof value === 'string') {
		return value;
	} else if (value === null || value === undefined) {
		return '';
	} else {
		console.warn('Unexpected type:', typeof value, value);
		return '';
	}
};

// sets and returns order headers screen
const OrderHead: React.FC = () => {
	const navigate = useNavigate();

	// set to true when active API call is happening
	const [loading, setLoading] = useState<boolean>(true);

	// true if the screen is initiated
	const [init, setInit] = useState<boolean>(false);

	// order id from url param
	const { orderId, cac } = useParams<{ orderId: string; cac: string }>();

	// can set the array of line items
	const [lineItems, setLineItems] = useState<LineItemType[]>([]);

	// can set the order details on the top of the screen
	const [orderDetails, setOrderDetails] = useState<Partial<OrderDetails>>({});

	// can set active filter of the screen
	const [activeFilter, setActiveFilter] = useState<FilterType>('All');

	// can set the search text which can be line item name
	const [searchText, setSearchText] = useState<string>('');

	// can set current page of data
	const [page, setPage] = useState<number>(0);

	// can set count of line items from each filter
	const [meta, setMeta] = useState<Record<string, number>>({
		ALL: 0,
		BACKORDERED: 0,
		CANCELLED: 0,
		COMPLETE: 0,
		IN_PROGRESS: 0,
		SUBMITTED: 0,
	});

	// can enable if the api call is executable or not
	const [mustSearch, setMustSearch] = useState<boolean>(true);

	// can set whether data should be reset or not
	const [resetAccountList, setResetAccountList] = useState<boolean>(true);

	// sets active filter, if current filter is same as active filter it returns
	const setFilter = (filterName: FilterType) => {
		if (filterName === activeFilter) {
			return;
		}
		setPage(0);
		setLineItems([]);
		setActiveFilter(filterName);
		setResetAccountList(true);
		setMustSearch(true);
	};

	// can set the product images for all the line items loaded, if exists
	const [productImages, setProductImages] = useState<Record<string, ImageData>>(
		{},
	);

	// can set product image token
	const [tokenTaken, setTokenTaken] = useState<boolean>(false);

	// sets page title
	useEffect(() => {
		document.title = `${BASE_TITLE} - Order Head`;
	}, []);

	// if the product image is not set, then obtain the token from the image api
	if (!tokenTaken) {
		setTokenTaken(true);
		getProductImagesToken()
			.then(token => {
				sessionStorage.setItem('imageToken', JSON.stringify(token));
			})
			.catch();
	}

	// api call wrapper function to load orders
	const loadOrders = () => {
		setLoading(true);
		// execute actual service call with validated params
		getOrders(getSearchParams(activeFilter, searchText, orderId!, page))
			.then((response: OrderApiResponse[]) => {
				// if nodata or error in the api, returns without further processing
				if (response.length === 0 || response[0].error) {
					setLoading(false);
					return;
				}
				const resmeta = response[0].order_lines_summary!;
				// sets the count of each filter as meta
				setMeta(resmeta);
				let items: LineItemType[] = [];
				if (
					response[1] &&
					response[1].orderItems &&
					response[1].orderItems.length > 0
				) {
					const order = response[1];
					if (!init) {
						// sets order details only at the initialize phase of the screen
						setOrderDetails({
							orderNum: order.order_number?.split('-')[0] ?? '',
							accountName: order.shipto_cus_name ?? '',
							accountId: order.cus_shipto_num ?? '',
							poNumber: order.po_number ?? '',
							requestedDate: order.order_placement_date ?? '',
							totalAmount: Number(order.total_price) || 0,
							currencyCode: order.currency ?? '',
							updatedDate: order.updated_date_time ?? '',
							prcntComplete: Number(order.ordr_prcnt_complete) || 0,
							orderStatus: order.order_status ?? '',
							orderRecordId: order.order_number ?? '',
							orderType: order.order_type ?? '',
							type: order.type_ot ?? '',
							description: order.description_ot ?? '',
						});
					}
					setInit(true);

					// return line items with correct keys
					try {
						items =
							order.orderItems?.map((lineItem: OrderItem) => {
								loadImage(lineItem.line_item_product_code, setProductImages);
								return {
									productCode: lineItem.line_item_product_code,
									productName: lineItem.line_item_product_desc,
									productQuantity: lineItem.line_item_pro_quantity,
									lineTotal: Number(lineItem.product_line_cost) || null,
									currencyCode: lineItem.currency,
									itemStatus: lineItem.o_line_item_status,
									estimatedDeliveryDate:
										lineItem.line_item_estimated_delivery_date || '',
									deliveredDate: handleEmptyOrArray(
										lineItem.line_item_delivered_date,
									),
									invoiceNumber: handleEmptyOrArray(lineItem.invoice_number),
								};
							}) ?? [];
					} catch (error) {
						console.error('Error mapping items:', error);
					}
				}

				// check and set line items
				canSetLineItems(items, setLineItems, resetAccountList);

				// set false the loading state since api execution is done
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				canSetLineItems([], setLineItems, true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		// api call execute only if mustSearch is true
		if (mustSearch) {
			loadOrders();
		}
	}, [activeFilter, page, mustSearch]);

	// check if element is child of filtering
	const isInFiltering = (node: Node | null): boolean => {
		while (node) {
			if (
				node instanceof HTMLElement &&
				typeof node.className === 'string' &&
				node.className.indexOf('filter') > -1
			) {
				return true;
			}
			node = node.parentNode;
		}
		return false;
	};

	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		const totalItems = meta[activeFilter] ? meta[activeFilter] : meta['ALL'];
		const shouldLoadMore = handleGeneralScroll({
			event: e,
			currentDataLength: lineItems.length,
			isLoading: loading ? 1 : 0, // Convert boolean to number
			totalItemCount: totalItems,
			currentPage: page.toString(),
			itemsPerPage: limit,
		});
		if (shouldLoadMore) {
			setResetAccountList(false);
			setMustSearch(true);
			setPage(page + 1);
		}
	};

	// search order line item
	const initSearch = useCallback(
		(fromDebouncedSearch = false) => {
			setLineItems([]);
			setPage(0);
			setResetAccountList(true);
			setMustSearch(true);

			// sets cursor focus out side of search after search is triggered
			if (!fromDebouncedSearch) {
				const activeElement = document.activeElement;
				if (activeElement instanceof HTMLElement) {
					activeElement.blur();
				}
				window.focus();
				if (activeElement instanceof HTMLElement) {
					activeElement.blur();
				}
			}
		},
		[setLineItems, setPage, setResetAccountList, setMustSearch],
	);

	const debouncedSearch = useCallback(
		debounce(() => {
			initSearch(true);
		}, 400),
		[initSearch],
	);

	const handleSearchChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setMustSearch(false);
			const { value } = event.target;
			const deleting = searchText.length > value.length;
			setSearchText(value);
			if (value.trim() === '' && deleting) {
				// eslint-disable-next-line
				(debouncedSearch as any).cancel();
				initSearch();
			} else {
				debouncedSearch();
			}
		},
		[debouncedSearch, initSearch, searchText.length],
	);

	const handleInitSearch = (
		event?:
			| React.MouseEvent<HTMLDivElement, MouseEvent>
			| React.KeyboardEvent<HTMLInputElement>,
	) => {
		event?.preventDefault();
		initSearch();
	};

	return (
		<AppLayout topBarProps={{ backVisibility: true }}>
			<div
				className="common-page-container"
				onTouchStart={touchStart(0)}
				onTouchMove={touchMove}
				onTouchEnd={e => {
					if (!isInFiltering(e.target as Node)) {
						touchEnd(navigate, null, null, true)();
					}
				}}
			>
				<Breadcrumb
					items={[
						{
							title: t('orders'),
							path: '/orders',
						},
						{
							title: t('products'),
						},
					]}
				/>
				<Row justify="space-around" align="middle" className="order-head">
					<Col span={23} className="col-container">
						<Row
							justify="space-around"
							className="display-block"
							align="middle"
						>
							<Order
								order={orderDetails as OrderDetails}
								loading={isLineItemLoading(loading, init)}
								withNavigation={false}
								orderHead={true}
								accountId={cac}
							/>
						</Row>
						<Row className="display-block">
							<Col span={24}>
								<Input
									placeholder={t('searchOrder')}
									onChange={handleSearchChange}
									className="search"
									prefix={
										<Image
											preview={false}
											src="/icons/search-icon.png"
											onClick={handleInitSearch}
										/>
									}
									onPressEnter={handleInitSearch}
									onFocus={() => {
										document
											.getElementsByClassName('portraitMode')[0]
											.classList.add('search-active');
										document
											.getElementsByClassName('landscapeMode')[0]
											.classList.add('hidden');
									}}
									onBlur={() => {
										document
											.getElementsByClassName('portraitMode')[0]
											.classList.remove('search-active');
										document
											.getElementsByClassName('landscapeMode')[0]
											.classList.remove('hidden');
									}}
									allowClear
								/>
							</Col>
						</Row>
						<Row align="middle" className="display-block">
							<Col span={24} className="filter-container">
								<LineFilterButton
									onClick={() => {
										setFilter(ORDER_API_STATUS_TEXT.ALL as FilterType);
									}}
									activeFilter={activeFilter}
									filter={ORDER_API_STATUS_TEXT.ALL as FilterType}
									meta={meta}
								/>
								<LineFilterButton
									onClick={() => {
										setFilter(ORDER_API_STATUS_TEXT.BACKORDERED as FilterType);
									}}
									activeFilter={activeFilter}
									filter={ORDER_API_STATUS_TEXT.BACKORDERED as FilterType}
									meta={meta}
								/>
								<LineFilterButton
									onClick={() => {
										setFilter(ORDER_API_STATUS_TEXT.CANCELLED as FilterType);
									}}
									activeFilter={activeFilter}
									filter={ORDER_API_STATUS_TEXT.CANCELLED as FilterType}
									meta={meta}
								/>
								<LineFilterButton
									onClick={() => {
										setFilter(ORDER_API_STATUS_TEXT.IN_PROGRESS as FilterType);
									}}
									activeFilter={activeFilter}
									filter={ORDER_API_STATUS_TEXT.IN_PROGRESS as FilterType}
									meta={meta}
								/>
								<LineFilterButton
									onClick={() => {
										setFilter(ORDER_API_STATUS_TEXT.SUBMITTED as FilterType);
									}}
									activeFilter={activeFilter}
									filter={ORDER_API_STATUS_TEXT.SUBMITTED as FilterType}
									meta={meta}
								/>
								<LineFilterButton
									onClick={() => {
										setFilter(ORDER_API_STATUS_TEXT.COMPLETE as FilterType);
									}}
									activeFilter={activeFilter}
									filter={ORDER_API_STATUS_TEXT.COMPLETE as FilterType}
									meta={meta}
								/>
							</Col>
						</Row>
						<Row justify="space-around" align="middle">
							<Col span={24}>
								<Typography.Title className="filter-name-text" level={5}>
									{t(activeFilter)} <b>-</b> {t('total')}:{' '}
									{getFilterButtonText(meta, activeFilter)}
								</Typography.Title>
							</Col>
						</Row>
						<Row
							justify="space-around"
							align="middle"
							className="li-container"
							onScroll={handleScroll}
						>
							{lineItems.map((entry, index) => {
								return (
									<LineItem
										key={'line-item-' + index.toString()}
										entry={entry}
										isLoading={isLineItemLoading(loading, init)}
										imageData={productImages[entry.productCode]}
										orderId={orderId}
									/>
								);
							})}
							{getEmpty(lineItems.length, loading)}
							{getLoading(loading)}
						</Row>
					</Col>
				</Row>
			</div>
		</AppLayout>
	);
};

export default OrderHead;
