import { refreshToken } from './AuthService';
import { getUrl } from './HttpService';
import { OrderApiResponse, SearchParams } from '../types/api';

/**
 * Fetches orders from an API based on the provided query parameters.
 *
 * @param {SearchParams} queryParams - An object containing query parameters for the request.
 * @returns {Promise<OrderApiResponse[]>} - A Promise that resolves with the response data.
 */
export const getOrders = async (queryParams: SearchParams): Promise<OrderApiResponse[]> => {
  // Remove specific query parameters if they have certain values.
  if (queryParams.orderStatus === 'ALL') {
    delete queryParams.orderStatus;
  }
  if (queryParams.searchParam === '') {
    delete queryParams.searchParam;
  }
  delete queryParams.email;

  // Construct the URL for the API request.
  const url = getUrl(
    process.env.REACT_APP_FSR_API + '/api/selfserve/orders',
    queryParams,
  );

  // Get the token from sessionStorage
  const tokenString = sessionStorage.getItem('fsrToken');
  if (!tokenString) {
    throw new Error('Token not found in sessionStorage');
  }

  const token = JSON.parse(tokenString).access_token;

  // Perform a fetch request to the specified URL with headers.
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });

  // Parse the response data as JSON.
  const data: OrderApiResponse[] = await response.json();

  // Check if the response contains a token error and handle it by refreshing the token.
  if (data.length > 0 && data[0].error && data[0].error === 'Token has been revoked.') {
    await refreshToken();
    // Retry the getOrders function after refreshing the token.
    return getOrders(queryParams);
  }

  return data;
};
