import { ChangeEvent, KeyboardEvent } from 'react';
import { Image, Input } from 'antd';
import './AppSearch.scss';

type AppSearchProps = {
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onPressSearch: () => void;
  onPressEnter: () => void;
  onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
};

export default function AppSearch({
  placeholder,
  onChange,
  onPressSearch,
  onPressEnter,
  onKeyPress,
}: AppSearchProps) {
  return (
    <Input
      name="search"
      placeholder={placeholder}
      onChange={onChange}
      className="search"
      prefix={
        <Image
          preview={false}
          src="/icons/search-icon.png"
          onClick={onPressSearch}
        />
      }
      onPressEnter={onPressEnter}
      allowClear={true}
      onKeyPress={onKeyPress}
      onFocus={() => {
        document
          .getElementsByClassName('portraitMode')[0]
          .classList.add('search-active');
        document
          .getElementsByClassName('landscapeMode')[0]
          .classList.add('hidden');
      }}
      onBlur={() => {
        document
          .getElementsByClassName('portraitMode')[0]
          .classList.remove('search-active');
        document
          .getElementsByClassName('landscapeMode')[0]
          .classList.remove('hidden');
      }}
    />
  );
}
