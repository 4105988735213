import React from 'react';
import { Button, Image } from 'antd';
import { t } from 'i18next';
import { ORDER_API_STATUS_TEXT, FilterType } from '../../types/enums';

interface MetaType {
	[key: string]: number;
}

interface LineFilterButtonProps {
	onClick: () => void;
	activeFilter: FilterType;
	filter: FilterType;
	meta: MetaType;
}

const getFilterClass = (activeFilter: FilterType, filter: FilterType): string =>
	activeFilter === filter
		? `active-filter filter ${filter}-selected`
		: 'inactive-filter filter';

const getFilterButtonText = (meta: MetaType, filter: FilterType): number => {
	if (filter === 'All') {
		return meta && meta['ALL'] ? meta['ALL'] : 0;
	} else {
		return meta[filter] ? meta[filter] : 0;
	}
};

const getInactiveImage = (filter: FilterType): string => {
	switch (filter) {
		case 'All':
			return '/icons/all-dark.svg';
		case ORDER_API_STATUS_TEXT.BACKORDERED:
			return '/icons/backorder-dark.svg';
		case ORDER_API_STATUS_TEXT.CANCELLED:
			return '/icons/cancel-dark.svg';
		case ORDER_API_STATUS_TEXT.IN_PROGRESS:
			return '/icons/progress-dark.svg';
		case ORDER_API_STATUS_TEXT.SUBMITTED:
			return '/icons/submit-dark.svg';
		case ORDER_API_STATUS_TEXT.COMPLETE:
			return '/icons/filter-completed.png';
		default:
			return '/icons/all-dark.svg';
	}
};

const getActiveImage = (filter: FilterType): string => {
	switch (filter) {
		case 'All':
			return '/icons/all-light.svg';
		case ORDER_API_STATUS_TEXT.BACKORDERED:
			return '/icons/backorder-light.svg';
		case ORDER_API_STATUS_TEXT.CANCELLED:
			return '/icons/cancel-light.svg';
		case ORDER_API_STATUS_TEXT.IN_PROGRESS:
			return '/icons/progress-light.svg';
		case ORDER_API_STATUS_TEXT.SUBMITTED:
			return '/icons/submit-light.svg';
		case ORDER_API_STATUS_TEXT.COMPLETE:
			return '/icons/filter-done-inactive.png';
		default:
			return '/icons/all-light.svg';
	}
};

const getFilterIcon = (activeFilter: FilterType, filter: FilterType): string => {
	if (activeFilter === filter) {
		return getActiveImage(filter);
	} else {
		return getInactiveImage(filter);
	}
};

const LineFilterButton: React.FC<LineFilterButtonProps> = ({ onClick, activeFilter, filter, meta }) => {
	return (
		<Button
			icon={
				<Image
					className="filter-button-icon"
					preview={false}
					src={getFilterIcon(activeFilter, filter)}
				/>
			}
			onClick={onClick}
			className={getFilterClass(activeFilter, filter)}
		>
			&nbsp;&nbsp;{t(filter.toLowerCase().split(' ').join(''))} (
			{getFilterButtonText(meta, filter)})
		</Button>
	);
};

export { LineFilterButton, getFilterButtonText };
