import { Image, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppLayout } from '../components';
import '../styles/Onboarding.scss';
import { touchEnd, touchMove, touchStart } from '../utils/touchfunctionality';
import { BASE_TITLE, returnSvg } from '../utils/utility';

const Onboarding8: React.FC = () => {
  const [pageIndex] = useState<number>(7);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // sets page title
  useEffect(() => {
    document.title = `${BASE_TITLE} - Onboarding - One Last Thing`;
  }, []);

  const handleOnboardingEnd = () => {
    localStorage.setItem('onboarded', 'true');
    navigate('/accounts/updates', { state: location });
  };

  return (
    <AppLayout topBarProps={{ backVisibility: true, logoVisibility: false, callVisibility: false }} bottomNavigationVisibility={false}>
      <div
        className="onboarding-container text-overflow-auto"
        onTouchStart={touchStart(0)}
        onTouchMove={touchMove}
        onTouchEnd={touchEnd(navigate, 'onboarding/8', 'onboarding/7')}
      >
        <div className="img-container img-container8">
          <Image preview={false} src="/media/svg/onboarding8.svg" />
        </div>
        <div className="description">
          <div className="title-text">{t('navBackTitle')}</div>
          <div className="body-text">{t('navBack')}</div>
        </div>
        <div className="pagination-list" >
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) =>
            returnSvg(Boolean(pageIndex === index)),
          )}
        </div>
        <div className="button-container button-container-flex" >
          <Button
            block
            type="primary"
            size="large"
            onClick={handleOnboardingEnd}
          >
            {t('next')}
          </Button>
        </div>
      </div>
    </AppLayout>
  );
}

export default Onboarding8;
