import './Breadcrumb.scss';
import { Breadcrumb as BreadcrumbAntd, BreadcrumbProps, Flex } from 'antd';
import { Link } from 'react-router-dom';

type CustomBreadcrumbProps = {
  items: {
    title?: React.ReactNode;
    path?: string;
  }[];
};

const BreadcrumbItem: BreadcrumbProps['itemRender'] = (currentRoute, params, items, paths) => {
  const isLast = currentRoute?.path === items[items.length - 1]?.path;

  return isLast ? (
    <span>{currentRoute.title}</span>
  ) : (
    <Link to={`/${paths.join("/")}`}>{currentRoute.title}</Link>
  );
}

export default function Breadcrumb({ items }: CustomBreadcrumbProps) {
  return (
    <Flex className='breadcrumb-margin'>
      <BreadcrumbAntd className='breadcrumb' itemRender={BreadcrumbItem} items={items} />
    </Flex>
  );
}
