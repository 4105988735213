import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Image, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppLayout } from '../components';
import '../styles/HelpExample.scss';
import { touchEnd, touchMove, touchStart } from '../utils/touchfunctionality';
import { BASE_TITLE } from '../utils/utility';

const HelpExample: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageX = 0;
  const currentPageX = 0;
  const { t } = useTranslation();
  const [lang] = useState<string | null>(localStorage.getItem('lang'));

  useEffect(() => {
    document.title = BASE_TITLE + ' - Help - Example';
  }, []);

  return (
    <AppLayout topBarProps={{ backVisibility: true }} bottomNavigationVisibility={false}>
      <div
        className="common-page-container"
        onTouchStart={touchStart(0)}
        onTouchMove={touchMove}
        onTouchEnd={touchEnd(navigate, 'help/example', 'help/order-status-work')}>
        <Row
          justify="space-around"
          align="middle"
          className="help-example"
          style={{ left: `${currentPageX - pageX}px` }}>
          <Col span={23} className="col-container">
            {lang === 'en' && (
              <>
                <Row>
                  <Col span={24}>
                    <Image
                      className="help-image"
                      src="/media/Order-Head.png"
                      preview={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Image
                      className="help-image-2"
                      src="/media/Order-Line-Item.png"
                      preview={false}
                    />
                  </Col>
                </Row>
              </>
            )}
            {(lang === 'es' || lang === 'pt') && (
              <Row>
                <Col span={24}>
                  <Image
                    className="help-image"
                    src={`/media/${lang}/onboarding5.svg`}
                    preview={false}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <Typography.Title level={5} className="order-status-title">
                  {t('forExample')}
                </Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography.Paragraph className="order-status-description-1">
                  {t('orderStatusNeedAttention')}
                </Typography.Paragraph>
              </Col>
            </Row>
            <Row>
              <Col span={4} offset={10}>
                <div className="inactive-bullet bullet" />
                <div className="inactive-bullet bullet" />
                <div className="active-bullet bullet" />
              </Col>
            </Row>
            <Row style={{ position: 'fixed', bottom: '10px', width: '92%' }}>
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={() => {
                    navigate('/help', { state: location });
                  }}>
                  {t('backToSupport')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};

export default HelpExample;
