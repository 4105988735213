import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppLayout } from '../components';
import { BASE_TITLE } from '../utils/utility';
import './../styles/HelpLineItems.scss';

const HelpLineItems: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [lang] = useState < string | null > (localStorage.getItem('lang'));

  useEffect(() => {
    document.title = BASE_TITLE + ' - Help - Line Items';
  }, []);

  return (
    <AppLayout topBarProps={{ backVisibility: true }} bottomNavigationVisibility={false}>
      <div className="common-page-container">
        <Row justify="space-around" align="middle" className="help-line-items">
          <Col span={23} className="col-container">
            <Row>
              <Col span={24}>
                {lang === 'en' && (
                  <Image
                    className="help-image"
                    src="/media/lineItemList.png"
                    preview={false}
                  />
                )}
                {(lang === 'es' || lang === 'pt') && (
                  <Image
                    className="help-image"
                    preview={false}
                    src={`/media/${lang}/onboarding5.svg`}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography.Title level={5} className="order-status-title">
                  {t('ordeLineItemStatus')}
                </Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography.Paragraph className="order-status-description-1">
                  {t('orderBrokenLineItems')}
                </Typography.Paragraph>
              </Col>
            </Row>
            <Row style={{ position: 'fixed', bottom: '10px', width: '92%' }}>
              <Col span={24}>
                <Button
                  block
                  size="large"
                  type="primary"
                  onClick={() => {
                    navigate('/help', { state: location });
                  }}>
                  {t('backToSupport')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};

export default HelpLineItems;
