import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Card, Col, Empty, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	AppLayout,
	AppSearch,
	Breadcrumb,
	CircularProgressBar,
	Filters,
	Order,
} from '../components';
import { useDispatch, useStore } from '../context/StoreProvider';
import { ACTIONS } from '../context/storeReducer';
import useFetchOrders from '../hooks/useFetchOrders';
import '../styles/AccountOrders.scss';
import { FSR_ORDER_STATUS, ORDERS_FILTERS } from '../types/enums';
import { FetchOrdersResult, Params } from '../types/api';
import { OrdersFilters, OrderType } from '../types/interface';
import { touchEnd, touchMove, touchStart } from '../utils/touchfunctionality';
import { BASE_TITLE } from '../utils/utility';
import { handleGeneralScroll } from '../utils/scrollHelpers';

export default function AccountOrders() {
	const {
		fetchData,
		meta,
		accountOrders,
		isLoading,
		setHasMore,
		setAccountOrders,
		accountDetails,
	}: FetchOrdersResult = useFetchOrders() as FetchOrdersResult;
	const { ordersFilters } = useStore() as { ordersFilters: OrdersFilters };
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const location = useLocation();
	const { accountId } = useParams<{ accountId: string }>();

	const [mustSearch, setMustSearch] = useState(true);
	const [searchText, setSearchText] = useState('');
	const { t } = useTranslation();
	const [page, setPage] = useState(0);
	const [expanded, setExpand] = useState(
		localStorage.getItem('expand') !== null &&
		localStorage.getItem('expand') === 'true',
	);

	useEffect(() => {
		document.title = BASE_TITLE + ' - Account Head';
		if (location?.state?.backUrl === '/accounts/updates') {
			ordersFilters.sort = 2;
			setActiveFilters(ordersFilters);
			handleSortChange(2);
		} else {
			handleSortChange(3);
		}
	}, []);

	const LIMIT = 10;
	const [activeFilters, setActiveFilters] = useState<OrdersFilters>(ordersFilters);
	const statusIsAll = ordersFilters?.status === FSR_ORDER_STATUS.ALL;

	useEffect(() => {
		const filtersWasUpdated = activeFilters !== ordersFilters;
		if (filtersWasUpdated) {
			setAccountOrders([]);
			setPage(0);
			setMustSearch(true);
			setActiveFilters(ordersFilters);
		}
	}, [ordersFilters]);

	useEffect(() => {
		if (mustSearch) {
			setMustSearch(false);
			fetchData({
				limit: LIMIT,
				offset: page,
				sortType: ordersFilters?.sort,
				accNo: accountId,
				...(!statusIsAll ? { orderStatus: ordersFilters?.status } : {}),
				...(searchText ? { searchParam: searchText } : {}),
				...(ordersFilters?.date ? { creationDate: ordersFilters?.date } : {}),
				...(ordersFilters?.type ? { orderType: ordersFilters?.type } : {}),
			} as Params);
		}
	}, [mustSearch, searchText, page]);

	const initSearch = () => {
		(document.activeElement as HTMLElement)?.blur();
		setAccountOrders([]);
		setPage(0);
		setMustSearch(true);
		window.focus();
		(document.activeElement as HTMLElement)?.blur();
	};

	const mapOrder = (order: OrderType, index: number) => (
		<Order
			key={index}
			order={order}
			loading={isLoading}
			withNavigation
			accountId={accountDetails.accountId}
		/>
	);

	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		const totalItems = meta[ordersFilters?.status] || meta['ALL'];
		const shouldLoadMore = handleGeneralScroll({
			event: e,
			currentDataLength: accountOrders.length,
			isLoading: Number(isLoading),
			totalItemCount: totalItems!,
			currentPage: page.toString(),
			itemsPerPage: LIMIT,
		});
		if (shouldLoadMore) {
			setPage(page + 1);
			setHasMore(true);
			setMustSearch(true);
		}
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
		if (event.target.value === '') {
			initSearch();
		}
	};

	const handleSortChange = (value: number) => {
		dispatch({
			type: ACTIONS.UPDATE_FILTERS,
			payload: {
				name: ORDERS_FILTERS.SORT,
				value,
			},
		});
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			(document.activeElement as HTMLElement)?.blur();
		}
	};

	const isOrdersRoute = location.pathname === '/orders';
	const breadcrumbPath = isOrdersRoute ? '/orders' : '/accounts';

	const sanitizedMeta: Record<string, number> = Object.fromEntries(
		Object.entries(meta).map(([key, value]) => [key, typeof value === 'number' ? value : 0])
	);

	return (
		<AppLayout topBarProps={{ backVisibility: true }}>
			<div
				className="common-page-container"
				onTouchStart={touchStart(0)}
				onTouchMove={touchMove}
				onTouchEnd={touchEnd(navigate, null, null, true)}>
				<Breadcrumb
					items={[
						{
							title: isOrdersRoute ? t('orders') : t('accounts'),
							path: breadcrumbPath,
						},
						{
							title: t('account'),
						},
					]}
				/>

				<Row justify="space-around" align="middle" className="account-orders">
					<Col span={23} className="col-container">
						<Row
							justify="space-around"
							align="middle"
							className="accinfo-height display-block">
							<Col span={24}>
								<Card
									className="account-orders-top-container"
									loading={isLoading && !accountDetails}>
									<Typography.Title level={5} className="account-name">
										{accountDetails.accountName}
									</Typography.Title>
									{expanded ? (
										<MinusCircleOutlined
											onClick={() => {
												setExpand(false);
												localStorage.setItem('expand', 'false');
											}}
											className="expander"
										/>
									) : (
										<PlusCircleOutlined
											size={20}
											onClick={() => {
												setExpand(true);
												localStorage.setItem('expand', 'true');
											}}
											className="expander"
										/>
									)}
									{expanded && (
										<>
											<Typography.Text className="account-info">
												{t('account')} #{accountDetails.accountId}
											</Typography.Text>
											<br />
											<Typography.Text className="account-info">
												{t('address')} {accountDetails.accountAddress}
											</Typography.Text>
										</>
									)}
								</Card>
							</Col>
						</Row>
						<Row
							justify="space-around"
							align="middle"
							className="main-topic-height  display-block">
							<Col span={24}>
								<Typography.Title level={5} className="page-header-text">
									{t('orders')}
								</Typography.Title>
							</Col>
						</Row>
						{/* Search Bar */}
						<Row className="search-height  display-block">
							<Col span={24}>
								<AppSearch
									placeholder={t('orderSearch')}
									onChange={handleSearch}
									onPressSearch={initSearch}
									onPressEnter={initSearch}
									onKeyPress={handleKeyPress}
								/>
							</Col>
						</Row>
						{/* Filters */}
						<Row align="middle">
							<Col span={24}>
								<Filters meta={sanitizedMeta} />
							</Col>
						</Row>
						{/* Filter title */}
						<Row
							justify="space-around"
							align="middle"
							className="sub-topic-height">
							<Col span={24}>
								<Typography.Title className="filter-name-text" level={5}>
									{t(ordersFilters?.status)}
								</Typography.Title>
							</Col>
						</Row>
						{/* Orders List */}
						<Row
							justify="space-around"
							align="middle"
							className="order-container"
							onScroll={handleScroll}>
							{accountOrders.map((order: OrderType, index: number) => mapOrder(order, index))}
							{accountOrders.length === 0 && !isLoading && (
								<Empty description={t('nodata')} />
							)}
							{isLoading && (
								<CircularProgressBar
									percentage={10}
									status="exception"
									width="small"
								/>
							)}
						</Row>
					</Col>
				</Row>
			</div>
		</AppLayout>
	);
}
