import React from 'react';
import { Card, Col, Image, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
// import { getSubstituteProducts } from '../../services/ProductSubstitution';
import { getLocaleCurrencyFormat } from '../../utils/localCurrencyFormat';
// import { getFsrCountry } from '../../utils/utility';
import './LineItem.scss';
import { ImageData } from '../../types/interface';

// Define the expected response type from getSubstituteProducts.
// TODO: Detail the type of objects within 'substitution' when the service is typed.
// interface SubstituteProduct {
// 	substitution: { length: number }[];
// }

const LINE_ITEM_STATUS_MAP: { [key: string]: string } = {
	Backordered: 'backordered',
	Cancelled: 'cancelled',
	Canceled: 'canceled',
	In_progress: 'in_progress',
	Submitted: 'submitted',
	Shipped: 'shipped',
	Complete: 'complete',
	Completed: 'completed',
	'In Progress': 'in_progress',
};

type Entry = {
	productCode: string;
	productName: string;
	productQuantity: number;
	lineTotal: number | null;
	currencyCode: string;
	itemStatus: string; 
	deliveredDate: string | null;
	estimatedDeliveryDate: string | null;
	invoiceNumber?: string;
};

interface LineItemProps {
	entry: Entry;
	isLoading: boolean;
	imageData?: ImageData;
	orderId: string | undefined;
	showProdSub?: boolean;
}

// const enableSubstitutionLink = (
// 	entry: LineItemProps['entry'],
// 	substitutionCount: number,
// ): boolean =>
// 	entry.itemStatus !== 'Canceled' &&
// 	entry.itemStatus !== 'In_progress' &&
// 	entry.itemStatus !== 'Submitted' &&
// 	entry.itemStatus !== 'Completed' &&
// 	entry.itemStatus !== 'Shipped' &&
// 	substitutionCount > 0;

const getDeliverDate = (entry: LineItemProps['entry']) => {
	if (entry.itemStatus === 'Canceled') return '';
	if (entry.itemStatus === 'Completed') {
		if (entry.deliveredDate === null || entry.deliveredDate === '') return '';
		return (
			<Typography.Text className="line-item-info">
				{`${t('delivered')}: ${entry.deliveredDate ? dayjs(entry.deliveredDate).format('DD/MM/YYYY') : '-'}`}
			</Typography.Text>
		);
	} else {
		if (
			entry.estimatedDeliveryDate === null ||
			entry.estimatedDeliveryDate === ''
		)
			return '';
		return (
			<Typography.Text className="line-item-info">
				{`${t('estDelivery')}: ${entry.estimatedDeliveryDate ? dayjs(entry.estimatedDeliveryDate).format('DD/MM/YYYY') : '-'}`}
			</Typography.Text>
		);
	}
};

const LineItem: React.FC<LineItemProps> = ({
	entry,
	isLoading,
	imageData,
	// orderId,
	// showProdSub = true,
}) => {
	// const navigate = useNavigate();
	// const [substitutionCount
	// 	// , setSubstitutionCount
	// ] = useState(0);
    // Deactivate productSubtitution API due to the execution
	// useEffect(() => {
	// 	getSubstituteProducts(entry.productCode, getFsrCountry())
	// 		.then((response: SubstituteProduct[]) => {
	// 			if (response.length > 0) {
	// 				setSubstitutionCount(response[0].substitution.length);
	// 			}
	// 		})
	// 		.catch(() => {
	// 			console.log('This product does not have substitution details!');
	// 		});
	// }, [entry.productCode]);

	return (
		<Col span={24} key={'li-' + entry.productCode}>
			<Card className="line-item" loading={isLoading}>
				<Row>
					<Col span={7} flex={1}>
						<Image
							className="line-item-img"
							rootClassName="line-item-wrapper"
							src={
								imageData
									? imageData.imageUrl
									: '/media/missing-product-515x515.jpg'
							}
							alt={
								imageData
									? imageData.imageAlt
									: `Image not available for ${entry.productName}`
							}
						/>
						<Typography.Text className="line-item-info">
							{t(
								LINE_ITEM_STATUS_MAP[entry.itemStatus] !== undefined
									? LINE_ITEM_STATUS_MAP[entry.itemStatus]
											.toLowerCase()
											.split(' ')
											.join('')
									: '',
							)}
						</Typography.Text>
					</Col>
					<Col span={17}>
						<Row>
							<Col span={24}>
								<Typography.Title className="line-item-name">
									{entry.productName}
								</Typography.Title>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text className="line-item-info">
									{entry.productCode}
								</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text className="line-item-info">
									{t('qty')}: {entry.productQuantity}
								</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text className="line-item-info">
									{t('total')}{' '}
									{entry.lineTotal
										? getLocaleCurrencyFormat(
												entry.currencyCode,
												entry.lineTotal,
											)
										: 0}
								</Typography.Text>
							</Col>
						</Row>
						<Row>
							{getDeliverDate(entry) !== '' && (
								<Col span={24}>{getDeliverDate(entry)}</Col>
							)}
						</Row>
						{entry.itemStatus === 'Completed' && entry.invoiceNumber ? (
							<Row>
								<Col span={24}>
									<Typography.Text className="line-item-info">
										{`${t('invoice')}: ${entry.invoiceNumber}`}
									</Typography.Text>
								</Col>
							</Row>
						) : null}
						{/* <Row>
							<Col span={24}>
								{enableSubstitutionLink(entry, substitutionCount) &&
								showProdSub ? (
									<>
										<Typography.Text
											className="subs-link"
											onClick={() => {
												localStorage.setItem(
													'product',
													JSON.stringify({
														entry: entry,
														isLoading: false,
														imageData: imageData,
														orderId: orderId,
													}),
												);
												navigate(
													`/order/${orderId}/product-substitution/${entry.productCode}`,
													{ state: { backUrl: location.pathname } },
												);
											}}
										>
											{t('viewProdSub')}
										</Typography.Text>
										<Typography.Text className="line-item-info">
											({substitutionCount})
										</Typography.Text>
									</>
								) : (
									<Typography.Text className="line-item-info">
										{t('noProductSubstitution')}
									</Typography.Text>
								)}
							</Col>
						</Row> */}
					</Col>
				</Row>
			</Card>
		</Col>
	);
};

export default LineItem;
