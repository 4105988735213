import { Image } from 'antd';
import {
	COUNTRY_TO_CCNUMBER,
	COUNTRY_TO_FLAG,
	FSR_ORDER_STATUS,
} from '../types/enums';

// return order status icon for the given status
export const getOrderStatusIcon = status => {
	switch (status) {
		case FSR_ORDER_STATUS.COMPLETED:
			return (
				<Image
					preview={false}
					className="status-icon"
					src="/icons/filter-completed-icon.svg"
				/>
			);
		case FSR_ORDER_STATUS.NEEDS_ATTENTION:
			return (
				<Image
					preview={false}
					className="status-icon"
					src="/icons/filter-needs-attention-icon.svg"
				/>
			);
		case FSR_ORDER_STATUS.IN_PROGRESS:
			return (
				<Image
					preview={false}
					className="status-icon"
					src="/icons/filter-in-progress-icon.svg"
				/>
			);
		default:
			return '';
	}
};

// return bullet icon based on the status of the bullet
export function returnSvg(fillRed) {
	return !fillRed ? (
		<svg
			key={Date.now() + '-' + Math.random()}
			width="8"
			height="8"
			viewBox="0 0 8 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ margin: '0 2px' }}>
			<circle cx="4" cy="4" r="3.5" fill="white" stroke="#D8D8D8" />
		</svg>
	) : (
		<svg
			key={Date.now() + '-' + Math.random()}
			width="8"
			height="8"
			viewBox="0 0 8 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle cx="4" cy="4" r="4" fill="#CA0D1B" />
		</svg>
	);
}

// returns FSR country saved in localstorage
export const getFsrCountry = () => {
	return localStorage.getItem('fsr_country') !== null
		? localStorage.getItem('fsr_country')
		: 'UK';
};

// returns FSR email saved in localstorage
export const getFsrEmail = () => {
	return localStorage.getItem('fsr_email') !== null
		? localStorage.getItem('fsr_email')
		: '-';
};

// returns FSR number saved in localstorage
export const getFsrNumber = () => {
	return localStorage.getItem('fsr_number') !== null
		? localStorage.getItem('fsr_number')
		: '-';
};

// returns FSR name saved in localstorage
export const getFsrName = () => {
	return localStorage.getItem('fsr_name') !== null
		? localStorage.getItem('fsr_name')
		: '-';
};

// return FSR last login date time in local storage
export const getFsrLastLoginDate = () => {
	const date = new Date(localStorage.getItem('fsr_last_login_date'));
	return date.toLocaleDateString('en-GB');
};

// returns FSR customer care number saved in localstorage
export const getFsrCustomerCareNumber = () => {
    const countryCode =
        localStorage.getItem('fsr_country') !== null
            ? localStorage.getItem('fsr_country').toUpperCase()
            : 'UK';
    const languageCode = ((localStorage.getItem('fsr_country') === 'CENCA' || localStorage.getItem('fsr_country') === 'PUERTO RICO') && localStorage.getItem('lang') === 'en') ? ' EN' : '';
    return COUNTRY_TO_CCNUMBER[countryCode + languageCode];
};

// returns FSR customer care number saved in localstorage
export const getCountryFlag = () => {
	const countryCode =
		localStorage.getItem('fsr_country') !== null
			? localStorage.getItem('fsr_country').toUpperCase()
			: 'UK';
	return COUNTRY_TO_FLAG[countryCode];
};

// set base title
export const BASE_TITLE = 'J&J Customer Connect';
