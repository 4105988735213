import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppLayout } from '../components';
import '../styles/HelpOrderStatusWork.scss';
import { touchEnd, touchMove, touchStart } from '../utils/touchfunctionality';
import { BASE_TITLE } from '../utils/utility';

const HelpOrderStatusWork: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageX] = useState(0);
  const [currentPageX] = useState(0);
  const { t } = useTranslation();
  const [lang] = useState<string | null>(localStorage.getItem('lang'));

  useEffect(() => {
    document.title = `${BASE_TITLE} - Help - Order Status Work`;
  }, []);

  return (
    <AppLayout topBarProps={{ backVisibility: true }} bottomNavigationVisibility={false}>
      <div
        onTouchStart={touchStart(0)}
        onTouchMove={touchMove}
        onTouchEnd={touchEnd(navigate, 'help/example', 'help/order-status')}>
        <Row
          justify="space-around"
          align="middle"
          className="help-status-work"
          style={{ left: currentPageX - pageX + 'px' }}>
          <Col span={23} className="col-container">
            <Row>
              <Col span={24}>
                {lang === 'en' && (
                  <Image
                    className="help-image"
                    src="/media/help-image2.png"
                    preview={false}
                  />
                )}
                {(lang === 'es' || lang === 'pt') && (
                  <Image
                    className="help-image"
                    src={`/media/${lang}/onboarding4.svg`}
                    preview={false}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography.Title level={5} className="order-status-title">
                  {t('howStatusesWork')}
                </Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography.Paragraph className="order-status-description-1">
                  {t('orderStatusProd')}
                </Typography.Paragraph>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography.Paragraph className="order-status-description-2">
                  {t('letSeeExample')}
                </Typography.Paragraph>
              </Col>
            </Row>
            <Row>
              <Col span={4} offset={10}>
                <div className="inactive-bullet bullet"></div>
                <div className="active-bullet bullet"></div>
                <div className="inactive-bullet bullet"></div>
              </Col>
            </Row>
            <Row style={{ position: 'fixed', bottom: '10px', width: '92%' }}>
              <Col span={11}>
                <Button
                  block
                  size="large"
                  type="default"
                  onClick={() => {
                    navigate('/help');
                  }}>
                  {t('backToSupport')}
                </Button>
              </Col>
              <Col span={11} offset={2}>
                <Button
                  block
                  size="large"
                  type="primary"
                  onClick={() => navigate('/help/example', { state: location })}>
                  {t('next')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};

export default HelpOrderStatusWork;
