import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { User } from '../../types/adminPortal';
import './UserListItem.scss';

type UserListItemProps = {
	item: User;
};
export default function UserListItem({ item }: UserListItemProps) {
	const { t } = useTranslation();
	return (
		<Link
			to={`/profile/admin/users/${item.fsr_number}`}
			state={{ backUrl: window.location.pathname }}
			className="user-item"
		>
			<div className="users__list__item">
				<div className="users__list__description">
					<div className="users__list__description__name">{item.email}</div>
					<div className="users__list__main__description">
						<div className="users__list__main__description__text">
							{t('adminPortal.userList.WWID')}: {item.fsr_number}
						</div>
					</div>
				</div>
				<div className="rightArrow-container">
					<Image preview={false} src="/media/svg/list_item.svg" />
				</div>
			</div>
		</Link>
	);
}
