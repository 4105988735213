import { Image } from 'antd';
import { useTranslation } from 'react-i18next';

type AccountListItemProps = {
  item: {
    cac_account_address: string,
    cac_account_name: string,
    cac_account_number: string,
    cac_postal_code: string,
    created_date_time: string,
    key: string,
    updated_date_time: string,
    updated_orders_count: number,
  }
}
export default function AccountListItem({ item }: AccountListItemProps) {
  const { t } = useTranslation();
  return (
    <div className="account__list__item">
      <div className="account__list__description">
        <div className="account__list__description__name">
          {item.cac_account_name}
        </div>
        <div className="account__list__main__description">
          <div className="account__list__main__description__text">
            {t('account')} #{item.cac_account_number}
          </div>
          {item.updated_orders_count > 0 ? (
            <div className="account__list__main__description__text blue-text">
              [{item.updated_orders_count} {t('orders')}] {t('updated')}
            </div>
          ) : (
            <div className="account__list__main__description__text">
              {t('noUpdates')}
            </div>
          )}
        </div>
      </div>
      <div className="rightArrow-container">
        <Image preview={false} src="/media/svg/list_item.svg" />
      </div>
    </div>
  );
}
