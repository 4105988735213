import React, { useEffect } from 'react';
import { Form, Image, Select, Spin, message, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken, showLogin } from '../services/AuthService';
import { getProductImagesToken } from '../services/ProductImages';
import '../styles/LoginScreen.scss';
import { BASE_TITLE } from '../utils/utility';

const { Option } = Select;

const LoginScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search).entries(),
  );

  useEffect(() => {
    getProductImagesToken()
      .then(token => {
        sessionStorage.setItem('imageToken', JSON.stringify(token));
      })
      .catch(() => { });

    if (params.code) {
      getToken({
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_SSO_CLIENT_ID!,
        client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET!,
        redirect_uri: process.env.REACT_APP_URL!,
        code: params.code,
      })
        .then(res => {
          sessionStorage.setItem('fsrToken', JSON.stringify(res));
          localStorage.getItem('onboarded')
            ? navigate('/accounts/updates', { state: location })
            : navigate('/onboarding/1', { state: location });
        })
        .catch(error => {
          message.error(
            'Error occurred, Please click on login button again!',
            error,
          );
        });
    }
    // sets page title
    document.title = `${BASE_TITLE} - Login`;
  }, [params.code, navigate, location]);

  function onFinish() {
    showLogin();
  }

  if (params.code) {
    return (
      <Spin
        className="login-loading"
        indicator={<Image src="/icons/loading.gif" />}
      />
    );
  }

  return (
    <div className="login__screen__container">
      <div className="image-placeholder">
        <Image src="/media/svg/self_serve_image.svg" preview={false} />
      </div>
      <div className="login__screen__list">
        <Form
          onFinish={onFinish}
          className="form-container"
          initialValues={{
            'checkbox-group': ['A'],
          }}
        >
          <div className="login__screen__input__list"></div>

          <div className="frame31">
            <Button
              block
              size="large"
              type="primary"
              onClick={onFinish} >
              {t('Login')}
            </Button>
          </div>
        </Form>
      </div>
      <div className="language-selection">
        <Select
          defaultValue={localStorage.getItem('lang') || 'en'}
          style={{ width: 70 }}
          onChange={val => {
            localStorage.setItem('lang', val);
            window.location.reload();
          }}
        >
          <Option value="en">EN</Option>
          <Option value="es">ES</Option>
          <Option value="pt">PT</Option>
        </Select>
      </div>
    </div>
  );
}

export default LoginScreen;
