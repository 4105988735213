import React, { useContext, useReducer } from 'react';
import storeReducer, { initialStore } from './storeReducer';

const StoreContext = React.createContext(null)

/**
 * A context provider that manages the application store using a reducer.
 *
 * @param {Object} props - The component's properties.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the provider.
 * @returns {React.ReactElement} - The context provider component.
 */


const StoreProvider = ({ children }) => (
  <StoreContext.Provider value={useReducer(storeReducer, initialStore)}>
    {children}
  </StoreContext.Provider>
);

/**
 * Exposes the application store from the context.
 *
 * @returns {Object} - The application store.
 */
const useStore = () => useContext(StoreContext)[0];

/**
 * Exposes the dispatch function from the context.
 *
 * @returns {Function} - The dispatch function.
 */
const useDispatch = () => useContext(StoreContext)[1];

export { StoreContext, useDispatch, useStore };
export default StoreProvider;
