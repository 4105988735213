import { Empty, Input, Button } from 'antd';
import { useEffect, useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AccountListItem, AppLayout, CircularProgressBar } from '../components';
import { AccountListService } from '../services/AccountListService';
import { auditLogin } from '../services/AuthService';
import '../styles/AccountList.scss';
import { BASE_TITLE } from '../utils/utility';
import { handleGeneralScroll } from '../utils/scrollHelpers';
import {
	AccountListProps,
	AccountListResponse,
	AccountListType,
	SearchParams,
} from '../types/api';

const canListAccounts = (
	loading: number,
	list: AccountListType[],
	avgResponseTime: number,
): ReactElement => {
	const { t } = useTranslation();
	return (
		<>
			{list.length > 0 &&
				list
					.map((account, index) => {
						account.key = 'link-' + index.toString();
						return account;
					})
					.map(account => (
						<Link
							to={`/accounts/${account.cac_account_number}`}
							state={{ backUrl: window.location.pathname }}
							className="account-item"
							key={account.key}
						>
							<AccountListItem
								key={account.cac_account_number}
								item={account}
							/>
						</Link>
					))}
			{loading > 0 && (
				<div className="loading-container">
					<CircularProgressBar
						percentage={avgResponseTime}
						status="exception"
						width="small"
					/>
				</div>
			)}
			{loading < 1 && list.length === 0 && (
				<Empty className="empty-message" description={t('nodata')} />
			)}
		</>
	);
};

export const getSearchParams = (
	onlyUpdates: boolean,
	searchText: string,
	limit: number,
	pageNumber: number,
): SearchParams => {
	const searchParams: SearchParams = {
		updatedAccounts: onlyUpdates ? 'true' : 'false',
		limit,
		offset: pageNumber,
	};

	if (searchText !== '') {
		searchParams.searchParam = searchText;
	}

	return searchParams;
};

const checkIsLoading = (loading: number): number =>
	loading > 0 ? loading - 1 : 0;

const concatAccountList = (
	reset: boolean,
	data: AccountListType[],
	accountList: AccountListType[],
): AccountListType[] => (reset ? data : accountList.concat(data));

export default function AccountList({
	hasUpdates = false,
}: AccountListProps): ReactElement {
	const [loading, setLoading] = useState<number>(0);
	const [accountList, setAccountList] = useState<AccountListType[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [onlyUpdates, setOnlyUpdates] = useState<boolean>(hasUpdates);
	const [pageNumber, setPageNumber] = useState<number>(0);
	const limit: number = 10;
	const avgResponseTime = 5;
	const [mustSearch, setMustSearch] = useState<boolean>(true);
	const [reset, setReset] = useState<boolean>(true);
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		document.title = BASE_TITLE + ' - Account Index';
	}, []);

	useEffect(() => {
		setMustSearch(true);
		setOnlyUpdates(hasUpdates);
	}, [hasUpdates]);

	const pageSearch = () => {
		setLoading(loading + 1);
		const searchParams = getSearchParams(
			onlyUpdates,
			searchText,
			limit,
			pageNumber,
		);
		AccountListService(searchParams)
			.then((data: AccountListResponse) => {
				if (data.fsr_accounts.length === 0 || data.error || data.errorMessage) {
					setLoading(checkIsLoading(loading));
					return;
				}
				setAccountList(
					concatAccountList(reset, data.fsr_accounts, accountList),
				);
				// Update profile user information
				localStorage.setItem('fsr_country', data?.fsr_country || '');
				localStorage.setItem('fsr_name', data?.fsr_name || '');
				localStorage.setItem('fsr_number', data?.fsr_number || '');
				localStorage.setItem('fsr_email', data?.email || '');
				localStorage.setItem(
					'fsr_last_login_date',
					data?.last_login_date_time || '',
				);
				localStorage.setItem('fsr_profile', data?.fsr_profile || '');
				// Log login event
				auditLogin(
					localStorage.getItem('fsr_number')!,
					localStorage.getItem('fsr_email')!,
				);
				setLoading(checkIsLoading(loading));
			})
			.catch(() => {
				setLoading(checkIsLoading(loading));
			});
	};

	useEffect(() => {
		if (mustSearch) {
			setMustSearch(false);
			pageSearch();
		}
	}, [onlyUpdates, pageNumber, searchText, mustSearch]);

	const doSearch = () => {
		(document.activeElement as HTMLElement)?.blur();
		setAccountList([]);
		setPageNumber(0);
		setMustSearch(true);
		window.focus();
		(document.activeElement as HTMLElement)?.blur();
	};

	const switchTabData = (updates: boolean) => {
		setAccountList([]);
		setPageNumber(0);
		navigate(updates ? '/accounts/updates' : '/accounts');
	};

	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		const shouldLoadMore = handleGeneralScroll({
			event: e,
			currentDataLength: accountList.length,
			isLoading: loading,
			totalItemCount: undefined,
			currentPage: pageNumber.toString(),
			itemsPerPage: limit,
		});
		if (shouldLoadMore) {
			setPageNumber(pageNumber + 1);
			setReset(false);
			setMustSearch(true);
		}
	};

	return (
		<AppLayout>
			<div className="buttons-tab">
				<Button
					block
					type={onlyUpdates ? 'primary' : 'default'}
					onClick={() => switchTabData(true)}
				>
					{t('accountUpdates')}
				</Button>
				<Button
					block
					type={onlyUpdates ? 'default' : 'primary'}
					onClick={() => switchTabData(false)}
				>
					{t('allAccounts')}
				</Button>
			</div>

			<div className="search-container">
				<Input
					className="account__list__search"
					allowClear
					prefix={
						<img
							src="/icons/search-icon.png"
							onClick={doSearch}
							style={{ cursor: 'pointer' }}
							className="search-image"
							alt="search"
						/>
					}
					placeholder={t('searchAccountName')}
					onPressEnter={doSearch}
					onChange={e => {
						setSearchText(e.target.value);
						if (e.target.value === '') {
							doSearch();
						}
					}}
					onFocus={() => {
						document
							.getElementsByClassName('portraitMode')[0]
							.classList.add('search-active');
						document
							.getElementsByClassName('landscapeMode')[0]
							.classList.add('hidden');
					}}
					onBlur={() => {
						document
							.getElementsByClassName('portraitMode')[0]
							.classList.remove('search-active');
						document
							.getElementsByClassName('landscapeMode')[0]
							.classList.remove('hidden');
					}}
				/>
			</div>

			<div className="account__list__container" onScroll={handleScroll}>
				{canListAccounts(loading, accountList, avgResponseTime)}
			</div>
		</AppLayout>
	);
}
