import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

if (localStorage.getItem('lang') === null) {
	localStorage.setItem('lang', 'en');
}
const resources = {
	en: {
		translation: {
			loading: 'Loading...',
			navBackTitle: 'How to navigate back',
			navBack:
				'You have two ways to navigate back. Use the back button at the top left corner of your app, or swipe right or left from the edge of your screen.',
			orderSearch: 'Search by order#, po#, or product code or name',
			nodata: 'No Data',
			total: 'Total',
			hospitalNotes: 'Hospital Notes',
			supervisorEmail: 'Supervisor Email',
			supervisorPhoneNo: 'Supervisor Phone #',
			supervisorName: 'Supervisor Name',
			department: 'Department',
			orgName: 'Organisation Name',
			zipCode: 'Zip Code',
			state: 'State',
			country: 'Country',
			city: 'City',
			address1: 'Address 1',
			address2: 'Address 2',
			account: 'Account',
			accounts: 'Accounts',
			email: 'Email',
			Login: 'Login',
			WorkInformation: 'Work Information',
			preferredLanguage: 'Preferred Language',
			password: 'Password',
			rememberMe: 'Remember Me',
			forgotPassword: 'Forgot your password?',
			informationOnGo: 'Information on the go',
			welcomeString:
				'Welcome to the brand new JJCC mobile app! Here you will be able to access information regarding your orders anywhere and anytime through your mobile devices. ',
			getStarted: 'Get Started',
			dataRefresh: 'Data refresh & near real-time data',
			infoApp:
				'The information on the app reflect updates that are close to real-time. The data is also periodically refreshed to ensure you are given information that is accurate.',
			skipAll: 'Skip all',
			next: 'Next',
			orderStatuses: 'Order statuses',
			identifyOrderIssues:
				'To easily identify orders issues, orders have one of three statuses needs attentions, in progress or completed. The statuses are determined by the order’s products.',
			orderDate: 'Order Date',
			complete: 'Complete',
			updated: 'Updated',
			howStatusWork: 'How order statuses work',
			orderStatus:
				'An order status is determined by the product’s status within the order. The Needs Attention status will always take priority over the other statuses',
			letSeeExample: 'Let’s see an example!',
			ifAnyProduct: 'If any products are…',
			backOrdered: 'Backordered',
			backordered: 'Backordered',
			ifAllProduct: 'Or if all products are…',
			canceled: 'Canceled',
			thenOrdered: 'Then the order …',
			needsAttention: 'Needs Attention',
			'Needs Attention': 'Needs Attention',
			shipped: 'Shipped',
			inProgress: 'In Progress',
			inprogress: 'In Progress',
			'In Progress': 'In Progress',
			submitted: 'Submitted',
			noOrderLine: 'And no order line items need attention then the order is …',
			completed: 'Completed',
			Completed: 'Completed',
			orIfSomeProd: 'Or if some products are',
			forExample: 'For example',
			orderStatusNeedAttention:
				'The order’s status is set to Needs Attention because a product is Backordered. You will also notice a progress bar representing the number of products that are Completed.',
			productCode: 'Product Code',
			productName: 'Product Name',
			quantity: 'Qty',
			estDelivery: 'Est. Delivery',
			viewProdSub: 'View Product Substitutions',
			statusIndividualProd:
				'See the status of individual products within an order and related status information. When applicable, you will also be able to view product substitutions.',
			seeWebApp: 'Save the website as an app',
			seeWebAppBrowser:
				'Depending on the browser used, you will be able to save the website like any other application on your device. Follow the instructions above.',
			appleUserOnly: 'Apple Users (Safari Only)',
			tapShareButton: 'Tap the Share Button',
			tapHomeScreen: 'Tap Add to Home Screen',
			enterNameSafari:
				'Enter the name for the shortcut and tap Add. Safari will close automatically and you will see the shortcut on your home screen.',
			androidUserOnly: 'Android Users (Google Chrome Only)',
			tapMenuIcon: 'Tap the Menu Icon',
			enterNameShortcut: 'Enter the name for the shortcut and tap Add',
			oneLastThing: 'One last thing!',
			helpImproveExperience:
				'Help us improve your experience! We’re conducting on-going research to make this app even better and would love your input! Would you like us to reach out to you for your feedback at some point?',
			mayBeLater: 'Maybe Later',
			yesIdLove: "Yes, I'd love to help!",
			refreshPage:
				'Something went wrong. Please refresh the page to load data. ',
			dashboardComingSoon: 'Dashboard coming soon!',
			feedbackStringInfo:
				'In the meantime, feel free to use the orders and accounts pages to access information.',
			feedbackString:
				'If you have feedback that you want to share with us regarding any pages, including the dashboard, navigate to the support page to opt in to future feedback sessions.',
			accountUpdates: 'Account Updates',
			allAccounts: 'All Accounts',
			searchAccountName: 'Search by account name, account#, or postal code',
			productSearch: 'Search by product name or product code',
			orders: 'Orders',
			noUpdates: 'No updates',
			searchOrder: 'Search by product code or name',
			all: 'All',
			All: 'All',
			ALL: 'All',
			pastMonth: 'Past Month',
			clickProduct: 'Click a product to call a customer service representative',
			each: 'Each',
			match: 'Match',
			call: 'Call',
			placeOrder: 'Place Order Buyer Group, CSR Group',
			contactInformation: 'Contact Customer Service',
			wwID: 'WWID',
			lastLoginDate: 'Last login Date',
			faxNo: 'Fax #',
			addresses: 'Addresses',
			support: 'Support',
			infoGuide: 'Information Guide',
			readStatuses: 'How do I read order statuses?',
			readLine: 'How do I read order line items?',
			leaveFeedback: 'Leave Feedback',
			contactedFeedback: 'I want to be contacted to provide my feedback',
			callCustomerService: 'Call Customer Service',
			statuses:
				'To easily understand the status of your various orders, they will be classified under 1 of 3 statuses: needs attention, in progress, or completed. ',
			letSeeHowThisWork: 'Let’s see how this works!',
			backToSupport: 'Back to Support',
			howStatusesWork: 'How order statuses work',
			orderStatusProd:
				'An order status is determined by the product’s status within the order. The Needs Attention status will always take priority over the other statuses',
			pleaseEnterEmail: 'Please Enter Your Email',
			pleaseEnterPassword: 'Please Enter Your Password',
			letseeHowThisWork: 'Let’s see how this works!',
			ordeLineItemStatus: 'Order line item status',
			orderBrokenLineItems:
				'Orders will be broken down into individual order line items to allow information relating directly to its order status to be easily identified. When applicable, product substitutions can be viewed.',
			updatedDate: 'Updated Date',
			order: 'Order',
			products: 'Products',
			productSubstitution: 'Product Substitutions',
			qty: 'QTY',
			delivered: 'Delivered',
			noProductSubstitution: 'No product substitute available',
			back: 'Back',
			address: 'Address',
			po: 'PO',
			searchProduct: 'Search by product code or name',
			typeLabel: 'Type',
			orderTypeLabel: 'Order type',
			descriptionLabel: 'Description',
			invoice: 'Invoice',
			orderTypeDescription: {
				ZOR: 'Direct Sales',
				ZINS: 'Institutional Sales',
				ZEX: 'Export Sales Order',
				ZSES: 'SINIEF Debit Note',
				ZKSE: 'Consignment order',
				ZSR2: 'Consignment order',
				ZKGW: 'Consignment order',
				ZKSB: 'Consignment order',
				ZFR: 'No Charge Order',
				ZMIS: 'Misc/Scrap Sales',
				ZSE2: 'Consignment order',
				ZRO: 'Rush Order Types',
				ZSM: 'Samples',
				'9LA': 'Return',
				'9SE0': 'SINIEF Return ins',
				'9SE8': 'Return',
				'9SIS': 'Loaner',
				ZKA: 'Consignment Pick-up',
				ZKB: 'Consignment order',
				ZKE: 'Consignment order',
				ZORD: 'Bill Only Order/export order',
				ZODR: 'Debit Memo Request',
				'9SES': 'Invoice',
				ZSER: 'Service',
				ZRED: 'Return',
				ZRFG: 'Returns Free Goods',
				ZCB: 'Service',
				'9SE3': 'Invoice',
			},
			types: {
				Sales: 'Sales',
				Freegoods: 'Freegoods',
				Consigned: 'Consigned',
			},
			descriptions: {
				Urgent: 'Urgent',
				Shipping: 'Shipping',
				Scheduling: 'Scheduling',
				Invoicing: 'Invoicing',
				Rebilling: 'Rebilling',
				regularSale: 'Regular Sale',
				fgFreegoods: 'FG - Freegoods - No charge order',
				dischargedPublic: 'Discharged Public ICMS - Government Orders',
				Freegoods: 'No charge order',
			},
			filters: {
				labels: {
					sort: 'Sort by',
					date: 'Pick a date',
					type: 'Type',
				},
				options: {
					sortOldest: 'Sort oldest to newest',
					sortNewest: 'Sort newest to oldest',
					sortUpdated: 'Last updated',
				},
			},
			button: {
				call: 'Call',
				chat: 'Chat',
				logoff: 'Logoff',
			},
			loginIdle: {
				title: 'Session Expired',
				content: 'Your session is expired. Redirecting to login!',
			},
			landscapeMode: 'Flip back to portrait mode to continue using the app.',
			adminPortal: {
				buttons: {
					listUsers: 'List Users',
					createNewUser: 'Create new user',
					okrs: 'OKRs',
				},
				sectionTitle: 'Admin Options',
				userList: {
					title: 'Users',
					search: 'Search by email or WWID',
					WWID: 'WWID',
				},
				userDetail: {
					title: 'User',
					search: 'Search accounts',
					subtitle: 'Accounts',
					userCard: {
						name: 'Name',
						WWID: 'WWID',
						country: 'Country',
						role: 'Role',
						lastLogin: 'Last login',
						loginCounter: 'Login counter',
						userStatus: {
							activate: 'Activate user',
							inactivate: 'Inactivate user',
							confirmActivate:
								'Are you sure that you want to activate this user?',
							confirmInactivate:
								'Are you sure that you want to inactivate this user?',
							activateButton: 'Activate',
							inactivateButton: 'Inactivate',
							cancelButton: 'No',
						},
					},
					selectedAccounts: 'Selected accounts',
					button: {
						save: 'Save',
						cancel: 'Cancel',
					},
				},
				okrs: {
					title: 'OKRs',
					adherence: 'Adherence',
					frequency: 'Frequency',
					desktopAdherence: 'Desktop Adherence',
				},
				userNew: {
					title: 'New user',
					emailOrWWID: 'Email or WWID',
					subtitle: 'Accounts',
					userCard: {
						name: 'Name',
						WWID: 'WWID',
						email: 'Email',
					},
					country: 'Country',
					role: 'Role',
					selectedOption: 'Select an option',
					selectedAccounts: 'Selected accounts',
					button: {
						create: 'Create',
						cancel: 'Cancel',
					},
					creationMessage: {
						success: 'User Created',
						error: 'Error creating user',
					},
				},
				errors: {
					'E-001': 'User by email not found',
					'E-003': 'This user is already registered with JJCC',
					'E-004': 'Not valid user or wwid',
					'E-005': 'Inactive user in JJEDS',
					'E-006': 'An unknown error ocurred',
				},
			},
			feedbackForm: {
				title: 'Leave Feedback',
				ratingLabel: 'How satisfied are you with JJCC?',
				commentLabel: 'Optional comment',
				commentPlaceholder:
					'If you have any additional feedback, please type it in here',
				successTitle: 'Feedback submitted',
				successContent:
					'Thank you for your feedback! Your opinion is very important to us.',
				submitButton: 'Submit feedback',
				ratingErrorMessage: 'Please rate us!, this field is required',
			},
		},
	},
	es: {
		translation: {
			loading: 'Cargando...',
			navBackTitle: 'Cómo navegar hacia atrás',
			navBack:
				'Tienes dos formas de volver atrás. Utiliza el botón de retroceso situado en la esquina superior izquierda de la aplicación o desliza el dedo hacia la derecha o hacia la izquierda desde el borde de la pantalla.',
			orderSearch:
				'Busque por número de pedido, número de pedido o código o nombre del producto',
			nodata: 'Sin datos',
			back: 'Atrás',
			total: 'Total',
			hospitalNotes: 'Notas del hospital',
			supervisorEmail: 'Email del supervisor',
			supervisorPhoneNo: 'Teléfono del supervisor',
			supervisorName: 'Nombre del supervisor',
			department: 'Departamento',
			orgName: 'Nombre de la organización',
			zipCode: 'Código postal',
			state: 'Estado',
			country: 'País',
			city: 'Ciudad',
			address1: 'Dirección 1',
			address2: 'Dirección 2',
			account: 'Cuenta',
			accounts: 'Cuentas',
			email: 'Correo electrónico',
			Login: 'Iniciar sesión',
			WorkInformation: 'Información laboral',
			preferredLanguage: 'Idioma preferido',
			password: 'Contraseña',
			rememberMe: 'Acuérdate de mí',
			forgotPassword: '¿Olvidaste tu contraseña?',
			informationOnGo: 'Información sobre la marcha',
			welcomeString:
				'¡Bienvenido a la nueva aplicación móvil JJCC! Aquí podrá acceder a la información de sus pedidos desde cualquier lugar y en cualquier momento a través de sus dispositivos móviles.',
			getStarted: 'Empezar',
			dataRefresh: 'Actualización de datos y datos casi en tiempo real',
			infoApp:
				'La información de la aplicación refleja actualizaciones casi en tiempo real. Los datos también se actualizan periódicamente para garantizar que reciba información precisa.',
			skipAll: 'Saltar todo',
			next: 'Próximo',
			orderStatuses: 'Estados de pedidos',
			identifyOrderIssues:
				'Para identificar fácilmente problemas con los pedidos, éstos tienen uno de los tres estados: necesita atención, en curso o completado. Los estados están determinados por los productos del pedido.',
			orderDate: 'Fecha de orden',
			complete: 'Completo',
			updated: 'Actualizado',
			howStatusWork: 'Cómo funcionan los estados de los pedidos',
			orderStatus:
				'El estado de un pedido está determinado por el estado del producto dentro del pedido. El estado Necesita atención siempre tendrá prioridad sobre los demás estados.',
			letSeeExample: ' ¡Veamos un ejemplo!',
			ifAnyProduct: 'Si algún producto es...',
			backOrdered: 'Pedido pendiente',
			backordered: 'Pedido pendiente',
			Backordered: 'Pedido pendiente',
			ifAllProduct: 'O si todos los productos son...',
			canceled: 'Cancelado',
			Canceled: 'Cancelado',
			thenOrdered: 'Luego el orden...',
			needsAttention: 'Necesita atención',
			'Needs Attention': 'Necesita atención',
			shipped: 'Enviado',
			Shipped: 'Enviado',
			inProgress: 'En curso',
			inprogress: 'En curso',
			'In Progress': 'En curso',
			submitted: 'Enviado',
			Submitted: 'Enviado',
			noOrderLine:
				'Y ninguna línea de pedido necesita atención, entonces el pedido es...',
			completed: 'Terminado',
			Completed: 'Terminado',
			orIfSomeProd: 'O si algunos productos son',
			forExample: 'Por ejemplo',
			orderStatusNeedAttention:
				'El estado del pedido se establece en Necesita atención porque un producto está en espera. También notará una barra de progreso que representa la cantidad de productos que se completaron.',
			productCode: 'Código de producto',
			productName: 'Nombre del producto',
			quantity: 'Cantidad',
			estDelivery: 'Est. Entrega',
			viewProdSub: 'Ver sustituciones de productos',
			statusIndividualProd:
				'Ver el estado del individual productos dentro de un pedido e información de estado relacionada. Cuando corresponda, también podrá ver sustituciones de productos.',
			seeWebApp: 'Guardar el sitio web como una aplicación',
			seeWebAppBrowser:
				'Dependiendo del navegador utilizado, podrá guardar el sitio web como cualquier otra aplicación en su dispositivo. Siga las instrucciones anteriores.',
			appleUserOnly: 'Usuarios de Apple (solo Safari)',
			tapShareButton: 'Toque el botón Compartir',
			tapHomeScreen: 'Toque Agregar a la pantalla de inicio',
			enterNameSafari:
				'Ingrese el nombre del atajo y toque Agregar. Safari se cerrará automáticamente y verás el acceso directo en tu pantalla de inicio.',
			androidUserOnly: 'Usuarios de Android (solo Google Chrome)',
			tapMenuIcon: 'Presiona el ícono de menú',
			enterNameShortcut: 'Ingrese el nombre del acceso directo y toque Agregar',
			oneLastThing: '¡Una última cosa!',
			helpImproveExperience:
				'¡Ayúdanos a mejorar tu experiencia! Estamos realizando investigaciones en curso para hacer que esta aplicación sea aún mejor y nos encantaría tu opinión. ¿Le gustaría que nos comuniquemos con usted para recibir sus comentarios en algún momento?',
			mayBeLater: 'Quizas mas tarde',
			yesIdLove: '¡Sí, me encantaría ayudar!',
			refreshPage: 'Algo salió mal. Actualice la página para cargar datos.',
			dashboardComingSoon: 'Tablero próximamente!',
			feedbackStringInfo:
				'Mientras tanto, no dude en utilizar las páginas de pedidos y cuentas para acceder a la información.',
			feedbackString:
				'Si tiene comentarios que desea compartir con nosotros con respecto a cualquier página, incluido el tablero, navegue a la página de soporte para participar en futuras sesiones de comentarios.',
			accountUpdates: 'Actualizaciones de cuenta',
			allAccounts: 'Todas las cuentas',
			searchAccountName:
				'Busque por nombre de cuenta, número de cuenta o código postal',
			productSearch: 'Búsqueda por nombre o código de producto',
			orders: 'Pedidos',
			noUpdates: 'Sin actualizaciones',
			searchOrder: 'Búsqueda por nombre o código de producto',
			all: 'Todo',
			All: 'Todo',
			ALL: 'Todo',
			pastMonth: 'El mes pasado',
			clickProduct:
				'Clique em um produto para ligar para um representante de atendimento ao cliente',
			each: 'Cada',
			match: 'Corresponder',
			call: 'Chamar',
			placeOrder: 'Realizar pedido Grupo de compradores, Grupo CSR',
			contactInformation: 'Contacte al Servicio al Cliente',
			wwID: 'WWID',
			lastLoginDate: 'Última fecha de inicio de sesión',
			faxNo: 'Número de fax',
			addresses: 'Direcciones',
			support: 'Apoyo',
			infoGuide: 'Guía de información',
			readStatuses: '¿Cómo leo los estados de los pedidos?',
			readLine: '¿Cómo leo las líneas de pedido?',
			leaveFeedback: 'Deja un comentario',
			contactedFeedback: 'Yo quierosercontactado para dar mi opinión',
			callCustomerService: 'Llamar a Servicio al Cliente',
			statuses:
				'Para comprender fácilmente el estado de sus diversos pedidos, se clasificarán en 1 de 3 estados: necesita atención, en curso o completado.',
			letSeeHowThisWork: '¡Veamos cómo funciona esto!',
			backToSupport: 'Volver a Soporte',
			howStatusesWork: 'Cómo funcionan los estados de los pedidos',
			orderStatusProd:
				'El estado de un pedido está determinado por el estado del producto dentro del pedido. El estado Necesita atención siempre tendrá prioridad sobre los demás estados.',
			pleaseEnterEmail: '',
			pleaseEnterPassword: '',
			letseeHowThisWork: '¡Veamos cómo funciona esto!',
			ordeLineItemStatus: 'Estado de la línea de pedido',
			orderBrokenLineItems:
				'Los pedidos se desglosarán en partidas individuales para permitir identificar fácilmente la información relacionada directamente con su estado. Cuando proceda, se pueden examinar',
			updatedDate: 'Fecha de actualización',
			order: 'Orden',
			products: 'Productos',
			productSubstitution: 'Sustituciones de productos',
			qty: 'Cantidad',
			delivered: 'Entregado',
			noProductSubstitution: 'No hay productos sustitutos disponibles',
			address: 'Dirección',
			po: 'Orden de compra',
			searchProduct: 'Search by product code or name',
			typeLabel: 'Tipo',
			orderTypeLabel: 'Tipo de orden',
			descriptionLabel: 'Descripción',
			invoice: 'Factura',
			orderTypeDescription: {
				ZOR: 'Venta Directa',
				ZINS: 'Venta Institucional',
				ZEX: 'Orden de Venta de Exportación',
				ZSES: 'SINIEF Nota Débito',
				ZKSE: 'Facturación Short Consignment',
				ZSR2: 'Reposiciones Consignment',
				ZKGW: 'Facturación Consignment',
				ZKSB: 'Reposiciones Short Consignment',
				ZFR: 'Orden sin cargo',
				ZMIS: 'Miscelaneas/Venta de Activo',
				ZSE2: 'Orden de Consignación',
				ZRO: 'Pedido Urgente de Ventas Regular',
				ZSM: 'Muestras',
				'9LA': 'Devolución',
				'9SE0': 'SINIEF Devolución institucional',
				'9SE8': 'Devolución',
				'9SIS': 'Prestamo',
				ZKA: 'Retiro consignado',
				ZKB: 'Reposiciones Consignment',
				ZKE: 'Facturación Consignment',
				ZORD: 'Orden Sólo Factura/Orden de Exportación',
				ZODR: 'Nota Débito',
				'9SES': 'Factura',
				ZSER: 'Servicio',
				ZRED: 'Devolución',
				ZRFG: 'Devoluciones de Productos Gratuitos',
				ZCB: 'Servicio',
				'9SE3': 'Factura',
			},
			types: {
				Sales: 'Ventas',
				Freegoods: 'Freegoods',
				Consigned: 'Consignado',
			},
			descriptions: {
				Urgent: 'Urgente',
				Shipping: 'Envío',
				Scheduling: 'Planificación',
				Invoicing: 'Facturación',
				Rebilling: 'Refacturación',
				regularSale: 'Venta regular',
				fgFreegoods: 'FG - Freegoods - Pedido sin cargo',
				dischargedPublic: 'ICMS público dado de alta - Órdenes gubernamentales',
				Freegoods: 'Freegoods',
			},
			filters: {
				labels: {
					sort: 'Ordenar por',
					date: 'Fecha',
					type: 'Tipo',
				},
				options: {
					sortOldest: 'De las mas antiguas a las mas recientes',
					sortNewest: 'De las mas recientes a las mas antigua',
					sortUpdated: 'Ultimas ordenes actualizadas',
				},
			},
			button: {
				call: 'Llamar',
				chat: 'Chat',
				logoff: 'Cerrar Sesión',
			},
			loginIdle: {
				title: 'Sesión Terminada',
				content: 'Tu sesion ha expirado. Redirigiendo al login!',
			},
			landscapeMode:
				'Vuelva al modo vertical para continuar usando la aplicación.',
			adminPortal: {
				buttons: {
					listUsers: 'Lista de usuarios',
					createNewUser: 'Crear nuevo usuario',
					okrs: "OKR's",
				},
				sectionTitle: 'Opciones de administrador',
				userList: {
					title: 'Usuarios',
					search: 'Buscar por correo electrónico o WWID',
					WWID: 'WWID',
				},
				userDetail: {
					title: 'Usuario',
					search: 'Buscar cuentas',
					subtitle: 'Cuentas',
					userCard: {
						name: 'Nombre',
						WWID: 'WWID',
						country: 'País',
						role: 'Rol',
						lastLogin: 'Último acceso',
						loginCounter: 'Contador de inicio de sesión',
						userStatus: {
							activate: 'Activar usuario',
							inactivate: 'Inactivar usuario',
							confirmActivate:
								'¿Está seguro de que desea activar este usuario?',
							confirmInactivate:
								'¿Está seguro de que desea inactivar este usuario?',
							activateButton: 'Activar',
							inactivateButton: 'Inactivar',
							cancelButton: 'No',
						},
					},
					selectedAccounts: 'Cuentas seleccionadas',
					button: {
						save: 'Guardar',
						cancel: 'Cancelar',
					},
				},
				okrs: {
					title: "OKR's",
					adherence: 'Adherencia',
					frequency: 'Frecuencia',
					desktopAdherence: 'Adherencia Desktop',
				},
				userNew: {
					title: 'Nuevo usuario',
					emailOrWWID: 'Correo electrónico o WWID',
					subtitle: 'Cuentas',
					userCard: {
						name: 'Nombre',
						WWID: 'WWID',
						email: 'Correo electrónico',
					},
					country: 'País',
					role: 'Rol',
					selectedOption: 'Seleccione una opción',
					selectedAccounts: 'Cuentas seleccionadas',
					button: {
						create: 'Crear',
						cancel: 'Cancelar',
					},
					creationMessage: {
						success: 'User Created',
						error: 'Error creating user',
					},
				},
				errors: {
					'E-001': 'Usuario por correo electrónico no encontrado',
					'E-003': 'Este usuario ya está registrado en JJCC',
					'E-004': 'Usuario o wwid no válido',
					'E-005': 'Usuario inactivo en JJEDS',
					'E-006': 'Se produjo un error desconocido',
				},
			},
			feedbackForm: {
				title: 'Deja tu feedback',
				ratingLabel: '¿Cuan satisfecho estas con JJCC?',
				commentLabel: 'Comentario opcional',
				commentPlaceholder:
					'Si tiene algún comentario adicional, escríbalo aquí',
				successTitle: 'Feedback enviado',
				successContent:
					'Gracias por sus comentarios. Su opinión es muy importante para nosotros.',
				submitButton: 'Enviar',
				ratingErrorMessage:
					'¡Por favor, califícanos! Este campo es obligatorio.',
			},
		},
	},
	pt: {
		translation: {
			loading: 'Carregando...',
			navBackTitle: 'Como navegar de volta',
			navBack:
				'Você tem duas maneiras de navegar de volta. Use o botão de voltar no canto superior esquerdo do seu aplicativo, ou deslize para a direita ou para a esquerda a partir da borda da tela.',
			orderSearch:
				'Pesquise por número do pedido, número do pedido ou código ou nome do produto',
			nodata: 'Sem dados',
			back: 'Voltar',
			total: 'Total',
			hospitalNotes: 'Notas hospitalares',
			supervisorEmail: 'E-mail do Supervisor',
			supervisorPhoneNo: 'Número de telefone do Supervisor',
			supervisorName: 'Nome do Supervisor',
			department: 'Departamento',
			orgName: 'Nome da organização',
			zipCode: 'CEP/Código Postal',
			state: 'Estado',
			country: 'País',
			city: 'Cidade',
			address1: 'Direção 1',
			address2: 'Endereço 2',
			account: 'Conta',
			accounts: 'Contas',
			email: 'Correio eletrônico',
			Login: 'Acesso',
			WorkInformation: 'Informações de trabalho',
			preferredLanguage: 'Língua preferida',
			password: 'Senha',
			rememberMe: 'Lembra de mim',
			forgotPassword: 'Você esqueceu sua senha?',
			informationOnGo: 'informações em movimento',
			welcomeString:
				'Bem-vindo ao novo aplicativo móvel JJCC! Aqui você pode acessar as informações do seu pedido de qualquer lugar e a qualquer momento através de seus dispositivos móveis.',
			getStarted: 'Começar',
			dataRefresh: 'Dados quase em tempo real e atualização de dados',
			infoApp:
				'As informações no aplicativo refletem as atualizações quase em tempo real. Os dados também são atualizados regularmente para garantir que você receba informações precisas.',
			skipAll: 'Pule tudo',
			next: 'Próximo',
			orderStatuses: 'Status do pedido',
			identifyOrderIssues:
				'Para identificar facilmente os problemas de pedidos, os pedidos têm um dos três status: precisam de atenção, em andamento ou concluídos. Os status são determinados pelos produtos no pedido.',
			orderDate: 'Data do pedido',
			complete: 'Completo',
			updated: 'Atualizada',
			howStatusWork: 'Como funcionam os status dos pedidos',
			orderStatus:
				'O status de um pedido é determinado pelo status do produto no pedido. O status Necessita de atenção sempre terá precedência sobre todos os outros status.',
			letSeeExample: 'Vejamos um exemplo!',
			ifAnyProduct: 'Se algum produto for...',
			backOrdered: 'Em falta - BO',
			backordered: 'Em falta - BO',
			Backordered: 'Em falta - BO',
			ifAllProduct: 'Ou se todos os produtos forem...',
			canceled: 'Cancelado',
			Canceled: 'Cancelado',
			thenOrdered: 'Então a ordem...',
			needsAttention: 'Precisa de atenção',
			'Needs Attention': 'Precisa de atenção',
			shipped: 'Enviado',
			Shipped: 'Enviado',
			inProgress: 'Em envio',
			inprogress: 'Em envio',
			'In Progress': 'Em envio',
			submitted: 'Submetido',
			Submitted: 'Submetido',
			noOrderLine:
				'E nenhuma linha de pedido precisa de atenção, então o pedido é...',
			completed: 'Finalizado',
			Completed: 'Finalizado',
			orIfSomeProd: 'Ou se alguns produtos são',
			forExample: 'Por exemplo',
			orderStatusNeedAttention:
				'O status do pedido é definido como Necessita de atenção porque um produto está em espera. Você também notará uma barra de progresso que representa o número de produtos concluídos.',
			productCode: 'Código do produto',
			productName: 'nome do produto',
			quantity: 'Quantia',
			estDelivery: 'Entrega estimada',
			viewProdSub: 'Ver Substituições de Produtos',
			statusIndividualProd:
				'Ver status individual produtos dentro de um pedido e informações de status relacionadas. Quando aplicável, você também poderá ver substituições de produtos.',
			seeWebApp: 'Salve o site como um aplicativo',
			seeWebAppBrowser:
				'Dependendo do navegador utilizado, você poderá salvar o site como qualquer outro aplicativo em seu dispositivo. Siga as instruções acima.',
			appleUserOnly: 'Usuários da Apple (somente Safari)',
			tapShareButton: 'Toque no botão Compartilhar',
			tapHomeScreen: 'Toque em Adicionar à tela inicial',
			enterNameSafari:
				'Insira o nome do atalho e toque em Adicionar. O Safari fechará automaticamente e você verá o atalho na tela inicial.',
			androidUserOnly: 'Usuários do Android (somente Google Chrome)',
			tapMenuIcon: 'Pressione o ícone do menu',
			enterNameShortcut: 'Digite o nome do atalho e toque em Adicionar',
			oneLastThing: 'Uma última coisa!',
			helpImproveExperience:
				'Ajude-nos a melhorar a sua experiência! Estamos fazendo pesquisas contínuas para tornar este aplicativo ainda melhor e adoraríamos receber seus comentários. Você gostaria que entrássemos em contato com você para feedback em algum momento?',
			mayBeLater: 'Talvez mais tarde',
			yesIdLove: 'Sim, eu adoraria ajudar!',
			refreshPage: 'Algo deu errado. Atualize a página para carregar os dados.',
			dashboardComingSoon: 'Painel em breve!',
			feedbackStringInfo:
				'Enquanto isso, sinta-se à vontade para usar as páginas de pedidos e contas para acessar as informações.',
			feedbackString:
				'Se você tiver comentários que gostaria de compartilhar conosco sobre qualquer página, incluindo o painel, navegue até a página de suporte para participar de futuras sessões de comentários.',
			accountUpdates: 'Atualizações de conta',
			allAccounts: 'Todas as contas',
			searchAccountName: 'Busque por código SAP, nº da ordem',
			productSearch: 'Busca por nome ou código do produto',
			orders: 'Ordens',
			noUpdates: 'Sem atualizações',
			searchOrder: 'Busca por nome ou código do produto',
			all: 'Todos',
			All: 'Todos',
			ALL: 'Todos',
			pastMonth: 'O mês passado',
			clickProduct:
				'Clique em um produto para ligar para um representante de atendimento ao cliente',
			each: 'Cada',
			match: 'Corresponder',
			call: 'Cancelar',
			placeOrder: 'Grupo de Compradores de Pedidos, Grupo CSR',
			contactInformation: 'Central de Relacionamento com o Consumidor',
			wwID: 'WWID',
			lastLoginDate: 'Última data de acesso',
			faxNo: 'Número de fax',
			addresses: 'Endereços',
			support: 'Apoiar',
			infoGuide: 'Guia de informações',
			readStatuses: 'Como faço para ler os status dos pedidos?',
			readLine: 'Como faço para ler as linhas do pedido?',
			leaveFeedback: 'Deixe um comentário',
			contactedFeedback: 'Eu querosercontactado para dar a minha opinião',
			callCustomerService: 'Ligue para o Atendimento ao Cliente',
			statuses:
				'Para entender facilmente o status de seus vários pedidos, eles serão categorizados em 1 de 3 status: Necessita de atenção, Em andamento ou Concluído.',
			letSeeHowThisWork: 'Vamos ver como isso funciona!',
			backToSupport: 'Voltar ao suporte',
			howStatusesWork: 'Como funcionam os status dos pedidos',
			orderStatusProd:
				'O status de um pedido é determinado pelo status do produto no pedido. O status Necessita de atenção sempre terá precedência sobre todos os outros status.',
			pleaseEnterEmail: 'Please Enter Your Email',
			pleaseEnterPassword: 'Please Enter Your Password',
			letseeHowThisWork: 'Vamos ver como isso funciona!',
			ordeLineItemStatus: 'Status da linha do pedido',
			orderBrokenLineItems:
				'Os pedidos serão divididos em itens de linha individuais para permitir a fácil identificação de informações diretamente relacionadas a seu status. Quando aplicável, podem ser examinados os seguintes itens',
			updatedDate: 'Data de atualização',
			order: 'Ordem',
			products: 'produtos',
			productSubstitution: 'Substituições de produtos',
			qty: 'Quantia',
			delivered: 'Entregue em',
			noProductSubstitution: 'Não há produtos substitutos disponíveis',
			address: 'Endereço',
			po: 'Pedido de compra',
			searchProduct: 'Search by product code or name',
			typeLabel: 'Tipo',
			orderTypeLabel: 'Tipo de pedido',
			descriptionLabel: 'Descrição',
			invoice: 'Fatura',
			orderTypeDescription: {
				ZOR: 'Vendas Diretas',
				ZINS: 'Venda Institucional',
				ZEX: 'Pedido de venda de exportação',
				ZSES: 'SINIEF Nota Débito',
				ZKSE: 'Pedido em consignação',
				ZSR2: 'Remessa agendamento',
				ZKGW: 'Pedido de faturamento Consignado',
				ZKSB: 'Pedido em consignação',
				ZFR: 'Ordem Sem Cobrança',
				ZMIS: 'Venda de Ativo',
				ZSE2: 'Remessa de consignado',
				ZRO: 'Pedido Urgente de Vendas Regular',
				ZSM: 'Amostras',
				'9LA': 'Devolução',
				'9SE0': 'SINIEF Devolução institucional',
				'9SE8': 'Devolução',
				'9SIS': 'Empréstimo',
				ZKA: 'Recusa da Consignação',
				ZKB: 'Remessa de consignado',
				ZKE: 'Faturamento de consignado',
				ZORD: 'Pedido somente fatura/pedido de exportação',
				ZODR: 'Nota Débito',
				'9SES': 'Fatura',
				ZSER: 'Serviço',
				ZRED: 'Devolução',
				ZRFG: 'Devoluções de Produtos Gratuitos',
				ZCB: 'Serviço',
				'9SE3': 'Fatura',
			},
			types: {
				Sales: 'Vendas',
				Freegoods: 'Freegoods',
				Consigned: 'Consignado',
			},
			descriptions: {
				Urgent: 'Urgente',
				Shipping: 'Remessa',
				Scheduling: 'Agendamento',
				Invoicing: 'Faturamento',
				Rebilling: 'Refaturamento',
				regularSale: 'Venda regular',
				fgFreegoods: 'FG - Freegoods - No charge order',
				dischargedPublic: 'Publico Desonerado ICMS - Government Orders',
				Freegoods: 'Sample',
			},
			filters: {
				labels: {
					sort: 'Ordenar por',
					date: 'Data',
					type: 'Tipo',
				},
				options: {
					sortOldest: 'Das mais antigas para as mais recentes',
					sortNewest: 'Das mais recentes para as mais antigas',
					sortUpdated: 'Últimas odens atualizadas',
				},
			},
			button: {
				call: 'Ligar',
				chat: 'Chat',
				logoff: 'Encerrar a Sessão',
			},
			loginIdle: {
				title: 'Sessão Expirada',
				content: 'Sua sessão expirou. Redirecionando para fazer login!',
			},
			landscapeMode:
				'Volte para o modo retrato para continuar usando o aplicativo.',
			adminPortal: {
				buttons: {
					listUsers: 'Listar usuários',
					createNewUser: 'Criar novo usuário',
					okrs: "OKR's",
				},
				sectionTitle: 'Opções de administrador',
				userList: {
					title: 'Usuários',
					search: 'Pesquisar por e-mail ou WWID',
					WWID: 'WWID',
				},
				userDetail: {
					title: 'Usuário',
					search: 'Pesquisar contas',
					subtitle: 'Contas',
					userCard: {
						name: 'Nome',
						WWID: 'WWID',
						country: 'País',
						role: 'Função',
						lastLogin: 'Último acesso',
						loginCounter: 'Registro de acesso',
						userStatus: {
							activate: 'Ativar usuário',
							inactivate: 'Desativar usuário',
							confirmActivate:
								'Você tem certeza de que deseja ativar este usuário?',
							confirmInactivate:
								'Você tem certeza de que deseja desativar este usuário?',
							activateButton: 'Ativar',
							inactivateButton: 'Desativar',
							cancelButton: 'Não',
						},
					},
					selectedAccounts: 'Contas selecionadas',
					button: {
						save: 'Salvar',
						cancel: 'Cancelar',
					},
				},
				okrs: {
					title: "OKR's",
					adherence: 'Aderência',
					frequency: 'Frequência',
					desktopAdherence: 'Aderência Desktop',
				},
				userNew: {
					title: 'Novo usuário',
					emailOrWWID: 'E-mail ou WWID',
					subtitle: 'Contas',
					userCard: {
						name: 'Nome',
						WWID: 'WWID',
						email: 'E-mail',
					},
					country: 'País',
					role: 'Função',
					selectedOption: 'Selecione uma opção',
					selectedAccounts: 'Contas selecionadas',
					button: {
						create: 'Criar',
						cancel: 'Cancelar',
					},
					creationMessage: {
						success: 'User Created',
						error: 'Error creating user',
					},
				},
				errors: {
					'E-001': 'E-mail do usuário não encontrado',
					'E-003': 'Este usuário já está registrado no JJCC',
					'E-004': 'Usuário ou wwid inválido',
					'E-005': 'Usuário inativo em JJEDS',
					'E-006': 'Ocorreu um erro desconhecido',
				},
			},
			feedbackForm: {
				title: 'Deixe seu feedback',
				ratingLabel: 'O quanto você está satisfeito com o JJCC?',
				commentLabel: 'Comentário opcional',
				commentPlaceholder:
					'Se você tem algum comentário adicional, por favor escreva aqui',
				successTitle: 'Feedback enviado',
				successContent:
					'Obrigada pelo seu feedback! Sua opinião é muito importante para nós!',
				submitButton: 'Enviar',
				ratingErrorMessage: 'Por favor, avalie-nos! Este campo é obrigatório.',
			},
		},
	},
};
i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: localStorage.getItem('lang'), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
