import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppContainer } from './components';
import StoreProvider from './context/StoreProvider';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/global.scss';
import { theme } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ConfigProvider theme={theme}>
      <StoreProvider>
        <AppContainer>
          <App />
        </AppContainer>
      </StoreProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
