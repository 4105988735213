import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ErrorPage: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className="error-container">
			<h1>Oops!</h1>
			<p>Sorry, an unexpected error has occurred.</p>
			<button onClick={() => navigate(-1)}>Go back</button>
			<Link to="/">Go to home page</Link>
		</div>
	);
};

export default ErrorPage;
