import React, { useState } from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Card, Col, Image, Progress, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocaleCurrencyFormat } from '../../utils/localCurrencyFormat';
import { getOrderStatusIcon } from '../../utils/utility';
import './Order.scss';

interface OrderDetails {
	accountId: string;
	orderRecordId: string;
	orderNum: string;
	accountName: string;
	poNumber: string;
	orderType?: string;
	type?: string;
	description?: string;
	requestedDate: string | null;
	currencyCode: string;
	totalAmount: number;
	updatedDate: string | null;
	prcntComplete: number;
	orderStatus: string | null;
}

interface OrderProps {
	order: Partial<OrderDetails>;
	loading: boolean;
	withNavigation: boolean;
	accountId?: string;
	orderHead?: boolean;
	allOrders?: boolean;
}

const Order: React.FC<OrderProps> = ({
	order,
	loading,
	withNavigation,
	accountId,
	orderHead = false,
	allOrders = false,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [expanded, setExpand] = useState(
		localStorage.getItem('expand') === null ||
			localStorage.getItem('expand') === 'true',
	);

	const orderNum = order.orderNum ?? '';
	const accountName = order.accountName ?? '';
	const poNumber = order.poNumber ?? '';
	const orderType = order.orderType ?? '';
	const type = order.type ?? '';
	const requestedDate = order.requestedDate
		? dayjs(order.requestedDate).format('DD/MM/YYYY')
		: '';
	const totalAmount = order.totalAmount ?? 0;
	const currencyCode = order.currencyCode ?? '';
	const updatedDate = order.updatedDate
		? dayjs(order.updatedDate).format('DD/MM/YYYY')
		: '';
	const prcntComplete = order.prcntComplete ?? 0;
	const orderStatus = order.orderStatus ?? '';

	return (
		<Col span={24} className="order">
			<Card
				className="order-item"
				loading={loading}
				onClick={event => {
					if (
						(event.target as HTMLElement).classList.contains(
							'order-item-account-info',
						)
					) {
						return;
					}
					if (!orderHead) {
						navigate(
							`/accounts/${order.accountId}/order/${order.orderRecordId}`,
							{
								state: {
									backUrl: location.pathname,
									...location.state,
								},
							},
						);
					}
				}}
			>
				<Row>
					<Col span={20}>
						<Row>
							<Col span={24}>
								<Typography.Title level={5} className="order-item-order-no">
									{t('order')} #{orderNum}
								</Typography.Title>
							</Col>
						</Row>
						{(expanded || !orderHead) && (
							<>
								<Row>
									<Col span={24}>
										<Typography.Text
											className="order-item-account-info"
											onClick={() => {
												if (orderHead) {
													navigate(
														`/accounts/${accountId === undefined ? order.accountId : accountId}`,
														{
															state: {
																backUrl: location.pathname,
																...location.state,
															},
														},
													);
												} else if (allOrders) {
													navigate(`/accounts/${accountId}`, {
														state: {
															backUrl: location.pathname,
															...location.state,
														},
													});
												} else {
													navigate(
														`/accounts/${order.accountId}/order/${order.orderRecordId}`,
														{
															state: {
																backUrl: location.pathname,
																...location.state,
															},
														},
													);
												}
											}}
										>
											{accountName} (#{order.accountId})
										</Typography.Text>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Typography.Text className="order-item-order-detail">
											{t('po')} #{poNumber}
										</Typography.Text>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Typography.Text className="order-item-order-detail">
											{`${orderType && orderType} - ${type && t([`orderTypeDescription.${orderType}`])}`}
										</Typography.Text>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<div className="space-maker" />
										<Typography.Text className="order-item-order-detail">
											{t('orderDate')}: {requestedDate}
										</Typography.Text>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Typography.Text className="order-item-order-detail">
											{t('total')}:{' '}
											{getLocaleCurrencyFormat(currencyCode, totalAmount)}
										</Typography.Text>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<div className="space-maker" />
										{updatedDate !== '' ? (
											<Typography.Text className="order-item-order-detail updated-date">
												{t('updatedDate')}: {updatedDate}
											</Typography.Text>
										) : (
											<Typography.Text className="order-item-order-detail updated-date">
												{t('noUpdates')}
											</Typography.Text>
										)}
									</Col>
								</Row>
								<Row>
									<Col span={14}>
										<Progress percent={prcntComplete} showInfo={false} />
									</Col>
									<Col span={10}>
										<Typography.Text className="order-item-order-detail">
											&nbsp;{`${prcntComplete}%`}
										</Typography.Text>
										<Typography.Text className="order-item-order-detail">
											&nbsp;{t('complete')}
										</Typography.Text>
									</Col>
								</Row>
							</>
						)}
					</Col>
					<Col span={3} offset={1} className="icon-container">
						{orderStatus && getOrderStatusIcon(orderStatus)}
						{withNavigation && (
							<div className="chavlon-icon">
								<Image preview={false} src="/icons/chavlon.png" />
							</div>
						)}
						{expanded && orderHead && (
							<MinusCircleOutlined
								onClick={() => {
									setExpand(false);
									localStorage.setItem('expand', 'false');
								}}
							/>
						)}
						{!expanded && orderHead && (
							<PlusCircleOutlined
								size={20}
								onClick={() => {
									setExpand(true);
									localStorage.setItem('expand', 'true');
								}}
							/>
						)}
					</Col>
				</Row>
			</Card>
		</Col>
	);
};

export default Order;
