import { Image, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppLayout } from '../components';
import '../styles/Onboarding.scss';
import { touchEnd, touchMove, touchStart } from '../utils/touchfunctionality';
import { BASE_TITLE, returnSvg } from '../utils/utility';

const Onboarding6: React.FC = () => {
  const [pageIndex] = useState<number>(5);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [lang] = useState<string | null>(localStorage.getItem('lang'));

  // sets page title
  useEffect(() => {
    document.title = `${BASE_TITLE} - Onboarding - Order Line Item Status`;
  }, []);

  return (
    <AppLayout topBarProps={{ backVisibility: true, logoVisibility: false, callVisibility: false }} bottomNavigationVisibility={false}>
      <div
        className="onboarding-container text-overflow-auto"
        onTouchStart={touchStart(0)}
        onTouchMove={touchMove}
        onTouchEnd={touchEnd(navigate, 'onboarding/7', 'onboarding/5')}
      >
        {lang === 'en' && (
          <div className="img-container img-container6">
            <Image preview={false} src="/media/svg/onboarding6.svg" />
          </div>
        )}
        {(lang === 'es' || lang === 'pt') && (
          <div className="img-container img-container6">
            <Image preview={false} src={`/media/${lang}/onboarding6.svg`} />
          </div>
        )}
        <div className="description description6">
          <div className="title-text">{t('ordeLineItemStatus')}</div>
          <div className="body-text">{t('statusIndividualProd')}</div>
        </div>
        <div className="pagination-list">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) =>
            returnSvg(Boolean(pageIndex === index)),
          )}
        </div>
        <div className="button-container button-container-flex">
          <Button
            block
            type="default"
            size="large"
            onClick={
              () => navigate('/accounts/updates', { state: location })
            }>
            {t('skipAll')}
          </Button>
          <Button
            block
            type="primary"
            size="large"
            onClick={() => navigate('/onboarding/7', { state: location })}
          >
            {t('next')}
          </Button>
        </div>
      </div>
    </AppLayout>
  );
}

export default Onboarding6;
