import { FetchAction, FetchState } from './useFetch.types';

export const initialState = {
	data: null,
	isLoading: false,
	error: '',
};

export const fetchStateReducer = <T>(
	state: FetchState<T>,
	action: FetchAction<T>,
): FetchState<T> => {
	switch (action.type) {
		case 'FETCH_RESET':
			return initialState;
		case 'FETCH_INIT':
			return { ...state, isLoading: true, error: null };
		case 'FETCH_SUCCESS':
			return { ...state, isLoading: false, data: action.payload };
		case 'FETCH_FAILURE':
			return {
				...state,
				isLoading: false,
				error: action.payload,
				data: null,
			};
		case 'FETCH_END':
			return { ...state, isLoading: false };
		default:
			throw new Error('Unhandled action type');
	}
};
