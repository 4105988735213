import { Button, Select } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppLayout } from '../components';
import '../styles/Profile.scss';
import {
	BASE_TITLE,
	getFsrEmail,
	getFsrLastLoginDate,
	getFsrName,
	getFsrNumber,
} from '../utils/utility';
import { LanguageOption } from '../types/interface';

export default function Profile() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const isAdmin = localStorage.getItem('fsr_profile') === 'admin' ?? false;

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Profile';
	}, []);

	const languageOptions: LanguageOption[] = [
		{
			value: 'en',
			label: 'English',
		},
		{
			value: 'es',
			label: 'Spanish',
		},
		{
			value: 'pt',
			label: 'Portuguese',
		},
	];

	return (
		<AppLayout>
			<div className="profile">
				<div className="name">{getFsrName()}</div>
				<div className="info-list">
					<div className="info-title">{t('contactInformation')}</div>
					<div className="info-container">
						<div className="desc">
							<div className="desc-title">{t('email')}</div>
							<div className="desc-value">{getFsrEmail()}</div>
							<div className="separator" />
						</div>
						<div className="desc">
							<div className="desc-title">{t('wwID')}</div>
							<div className="desc-value">{getFsrNumber()}</div>
							<div className="separator" />
						</div>
						<div className="desc">
							<div className="desc-title">{t('lastLoginDate')}</div>
							<div className="desc-value">{getFsrLastLoginDate()}</div>
							<div className="separator" />
						</div>
					</div>
				</div>
				<div className="info-list">
					<div className="info-title">{t('preferredLanguage')}</div>
					<Select
						defaultValue={localStorage.getItem('lang') ?? undefined}
						className="profile__select"
						options={languageOptions}
						onChange={val => {
							localStorage.setItem('lang', val);
							window.location.reload();
						}}
					></Select>
					<div className="separator" />
				</div>
				<div className="info-list">
					<Button
						type="default"
						block
						onClick={() => {
							sessionStorage.clear();
							navigate('/');
						}}
					>
						{t('button.logoff')}
					</Button>
				</div>
				{isAdmin ? (
					<>
						<div className="separator" />
						<div className="info-list">
							<div className="info-title">{t('adminPortal.sectionTitle')}</div>
							<Button
								className="button"
								size="large"
								type="primary"
								block
								onClick={() => {
									navigate('/profile/admin/users');
								}}
							>
								{t('adminPortal.buttons.listUsers')}
							</Button>
							<Button
								className="button"
								size="large"
								type="primary"
								block
								onClick={() => {
									navigate('/profile/admin/new-user');
								}}
							>
								{t('adminPortal.buttons.createNewUser')}
							</Button>
							<Button
								className="button"
								size="large"
								type="primary"
								block
								onClick={() => {
									navigate('/profile/admin/OKRs');
								}}
							>
								{t('adminPortal.buttons.okrs')}
							</Button>
						</div>
					</>
				) : null}
			</div>
		</AppLayout>
	);
}
