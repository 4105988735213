import React from 'react';
import { Button, Image } from 'antd';
import { useStore } from '../../context/StoreProvider';
import { FSR_ORDER_STATUS } from '../../types/enums';
import './OrderFilter.scss';

type FSR_ORDER_STATUS_TYPE = typeof FSR_ORDER_STATUS[keyof typeof FSR_ORDER_STATUS];

interface OrderFilterProps {
	onClick: () => void;
	text: number;
	filter: FSR_ORDER_STATUS_TYPE;
}

interface OrdersFilters {
	status: FSR_ORDER_STATUS_TYPE;
}

const OrderFilter: React.FC<OrderFilterProps> = ({ onClick, text, filter }) => {
	const { ordersFilters } = useStore() as { ordersFilters: OrdersFilters };

	const getFilterIcon = (): string => {
		if (ordersFilters?.status === filter) {
			switch (filter) {
				case FSR_ORDER_STATUS.NEEDS_ATTENTION:
					return '/icons/filter-needs-attention-icon.svg';
				case FSR_ORDER_STATUS.IN_PROGRESS:
					return '/icons/filter-in-progress-icon.svg';
				case FSR_ORDER_STATUS.COMPLETED:
					return '/icons/filter-completed-icon.svg';
				default:
					return '/icons/filter-all-icon-active.svg';
			}
		} else {
			switch (filter) {
				case FSR_ORDER_STATUS.NEEDS_ATTENTION:
					return '/icons/filter-needs-attention-icon.svg';
				case FSR_ORDER_STATUS.IN_PROGRESS:
					return '/icons/filter-in-progress-icon.svg';
				case FSR_ORDER_STATUS.COMPLETED:
					return '/icons/filter-completed-icon.svg';
				default:
					return '/icons/filter-all-icon.svg';
			}
		}
	};

	return (
		<Button
			icon={
				<Image
					className="filter-button-icon"
					preview={false}
					src={getFilterIcon()}
				/>
			}
			onClick={onClick}
			className={`filter ${ordersFilters?.status === filter ? 'active-filter' : ''
				}`}
		>
			({text !== undefined ? (text > 99 ? '99+' : text) : 0})
		</Button>
	);
};

export default OrderFilter;
