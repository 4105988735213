import React, { useEffect } from 'react';
import { Col, Divider, Row, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { CallIcon, ChatIcon } from '../icons';
import { AppLayout, FeedbackForm } from '../components';
import '../styles/Help.scss';
import { CUSTOMER_CHAT } from '../types/enums';
import { BASE_TITLE, getFsrCustomerCareNumber } from '../utils/utility';

const Help: React.FC = () => {
	const location = useLocation();
	const { t } = useTranslation();

	useEffect(() => {
		document.title = BASE_TITLE + ' - Help';
	}, []);

	const handleCallButton = () => {
		window.location.href = `tel:${getFsrCustomerCareNumber()}`;
	};

	const handleChatButton = () => {
		let url: string | undefined;
		const fsrCountry = localStorage.getItem('fsr_country') || '';
		const lang = localStorage.getItem('lang') || '';

		if (
			(fsrCountry === 'CENCA' || fsrCountry === 'PUERTO RICO') &&
			lang === 'en'
		) {
			url = CUSTOMER_CHAT[`${fsrCountry} EN` as keyof typeof CUSTOMER_CHAT];
		} else {
			url = CUSTOMER_CHAT[fsrCountry as keyof typeof CUSTOMER_CHAT];
		}

		if (url) {
			window.location.href = url;
		}
	};

	return (
		<AppLayout>
			<div className="common-page-container">
				<Row justify="space-around" align="middle" className="help">
					<Col span={23} className="col-container">
						<Row>
							<Col span={24}>
								<Typography.Title level={5} className="support-title">
									{t('support')}
								</Typography.Title>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Title level={5} className="support-sub-title">
									{t('infoGuide')}
								</Typography.Title>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Link
									to="/help/order-status"
									state={location}
									className="help-sub-link-1"
								>
									{t('readStatuses')}
								</Link>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Divider />
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Link
									to="/help/line-items"
									state={location}
									className="help-sub-link-2"
								>
									{t('readLine')}
								</Link>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Divider />
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Title level={5} className="contact-info-title">
									{t('contactInformation')}
								</Typography.Title>
							</Col>
						</Row>
						<Row gutter={16} className="contact-buttons">
							<Col span={12}>
								<Button
									block
									type="primary"
									size="large"
									icon={<CallIcon />}
									onClick={handleCallButton}
								>
									{t('button.call')}
								</Button>
							</Col>
							<Col span={12}>
								<Button
									block
									type="primary"
									size="large"
									icon={<ChatIcon />}
									onClick={handleChatButton}
								>
									{t('button.chat')}
								</Button>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Divider />
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Title level={5} className="contact-info-title">
									{t('feedbackForm.title')}
								</Typography.Title>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<FeedbackForm />
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</AppLayout>
	);
};

export default Help;
