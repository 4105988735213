import { useCallback, useRef, useReducer } from 'react';
import { FsrToken } from '../../types/adminPortal';
import { fetchStateReducer, initialState } from './fetchStateReducer';
import { UseFetchReturn } from './useFetch.types';

export function useFetch<T>(): UseFetchReturn<T> {
	const [state, dispatch] = useReducer(fetchStateReducer<T>, initialState);

	const abortControllerRef = useRef<AbortController | null>(null);

	const run = useCallback(
		async (
			url: string,
			options: RequestInit = {},
			params: Record<string, string> = {},
		) => {
			dispatch({ type: 'FETCH_INIT' });

			// Abort previous request if it exists
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}

			abortControllerRef.current = new AbortController();

			try {
				const queryParams = new URLSearchParams(params);
				const urlWithParams = `${url}?${queryParams?.toString()}`;
				const token: FsrToken | null = (() => {
					const storedToken = sessionStorage.getItem('fsrToken');
					if (storedToken === null) {
						return null;
					}
					try {
						return JSON.parse(storedToken) as FsrToken;
					} catch {
						return null;
					}
				})();

				const response = await fetch(
					`${queryParams.size > 0 ? urlWithParams : url}`,
					{
						headers: {
							Authorization: `Bearer ${token?.access_token}`,
							...options?.headers,
						},
						...options,
						signal: abortControllerRef.current.signal,
					},
				);

				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}

				const data = await response.json();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error: unknown) {
				if (error instanceof Error) {
					if (error.name === 'AbortError') {
						console.log('Fetch aborted');
					} else {
						dispatch({ type: 'FETCH_FAILURE', payload: error.message });
					}
				}
			}
		},
		[state],
	);

	const abort = useCallback(() => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
			abortControllerRef.current = null;
		}
	}, []);

	return { state, run, abort };
}
