// constants of order status
export const COMPOUND_ORDER_STATUS = {
	ALL: 'ALL',
	NEED_ATTENTION: 'NEED_ATTENTION',
	IN_PROGRESS: 'IN_PROGRESS',
	COMPLETED: 'COMPLETED',
};

// order status to order status text mapping
export const ORDER_STATS_TEXT = {
	ALL: 'All',
	NEED_ATTENTION: 'Need Attention',
	IN_PROGRESS: 'In Progress',
	COMPLETED: 'Completed',
};

// order status return from orders
export const ORDER_API_STATUS = {
	BACKORDERED: 'Backordered',
	CANCELLED: 'Canceled',
	IN_PROGRESS: 'InProgress',
	SUBMITTED: 'Submitted',
	COMPLETE: 'Completed',
	ALL: 'ALL',
};

// mapping text for api status to viewable
export const ORDER_API_STATUS_TEXT: Record<FilterType, string> = {
	BACKORDERED: 'Backordered',
	CANCELLED: 'Canceled',
	IN_PROGRESS: 'In Progress',
	SUBMITTED: 'Submitted',
	COMPLETE: 'Completed',
	ALL: 'All',
	All: 'All',
	NEED_ATTENTION: 'Need Attention',
	COMPLETED: 'Completed',
};

// Line item statuses
export const LINE_ITEM_STATUS_MAP = {
	Backordered: 'Backordered',
	Cancelled: 'Canceled',
	Canceled: 'Canceled',
	In_progress: 'In Progress',
	Submitted: 'Submitted',
	Complete: 'Complete',
	Completed: 'Completed',
	'In Progress': 'In Progress',
};

export const FSR_ORDER_STATUS = {
	IN_PROGRESS: 'In Progress',
	NEEDS_ATTENTION: 'Needs Attention',
	COMPLETED: 'Completed',
	ALL: 'ALL',
};

// Orders Filters
export const ORDERS_FILTERS = {
	STATUS: 'status',
	SORT: 'sort',
	DATE: 'date',
	TYPE: 'type',
	TYPE_OPTIONS_BR: [
		'Urgent',
		'Shipping',
		'Scheduling',
		'Invoicing',
		'Rebilling',
		'regularSale',
		'fgFreegoods',
		'dischargedPublic',
	],
	TYPE_OPTIONS_CO: ['Freegoods', 'regularSale', 'fgFreegoods'],
	TYPE_OPTIONS_MX: ['Freegoods', 'regularSale', 'fgFreegoods'],
};

// Initial order meta data
export const INITIAL_ORDERS_META = {
	[FSR_ORDER_STATUS.ALL]: 0,
	[FSR_ORDER_STATUS.COMPLETED]: 0,
	[FSR_ORDER_STATUS.NEEDS_ATTENTION]: 0,
	[FSR_ORDER_STATUS.IN_PROGRESS]: 0,
};

// Customer Service
export const CUSTOMER_CHAT = {
	BRAZIL: 'https://br.jjcustomerconnect.com/store/pt/help',
	CHILE: 'https://cl.jjcustomerconnect.com/store/es/help',
	COLOMBIA: 'https://co.jjcustomerconnect.com/store/es/help',
	MEXICO: 'https://mx.jjcustomerconnect.com/store/es/help',
	ECUADOR: 'https://ec.jjcustomerconnect.com/store/es/help',
	ARGENTINA: 'https://ar.jjcustomerconnect.com/store/es/help',
	'PUERTO RICO': 'https://pr.jjcustomerconnect.com/store/es/help',
	'PUERTO RICO EN': 'https://pr.jjcustomerconnect.com/store/en/help',
	PERU: 'https://pe.jjcustomerconnect.com/store/es/help',
	URUGUAY: 'https://uy.jjcustomerconnect.com/store/es/help',
	CENCA: 'https://cenca.jjcustomerconnect.com/store/es/help',
	'CENCA EN': 'https://cenca.jjcustomerconnect.com/store/en/help',
};

// Desktop adherence
export const DESKTOP_ADHERENCE =
	'https://app.powerbi.com/groups/840031a9-3843-4b4e-b3ea-235aeb87fe64/reports/c0de794f-7fe1-40de-afcd-32544d6ea243/ReportSection?experience=power-bi';

// Filter type definition
export type FilterType =
	| 'ALL'
	| 'BACKORDERED'
	| 'CANCELLED'
	| 'IN_PROGRESS'
	| 'SUBMITTED'
	| 'COMPLETE'
	| 'NEED_ATTENTION'
	| 'COMPLETED'
	| 'All';

// Country to call center number mapping
export const COUNTRY_TO_CCNUMBER = {
	UK: '0800864060',
	FR: '+33155002475',
	CH: '0800830085',
	BRAZIL: '0800-721-2097',
	ARGENTINA: '0800-444-4803',
	CHILE: '800 800 661',
	COLOMBIA: '18000 180 551',
	MEXICO: '1800 7097207',
	'PUERTO RICO': '1-855-708-8215',
	'PUERTO RICO EN': '1-833-708-8215',
	VENEZUELA: '800-102-9290',
	'DPS BR': '0800-707-2833',
	'COSTA RICA': '507 309 3401',
	BELIZE: '507 309 3401',
	CUBA: '507 309 3401',
	'DOMINICAN REPUBLIC': '507 309 3401',
	'EL SALVADOR': '507 309 3401',
	GUATEMALA: '507 309 3401',
	HAITI: '507 309 3401',
	HONDURAS: '507 309 3401',
	JAMAICA: '507 309 3401',
	NICARAGUA: '507 309 3401',
	'SAINT LUCIA': '507 309 3401',
	ESPAÑOL: '507 309 3401',
	ESPANOL: '507 309 3401',
	INGLÉS: '1787 708 8216',
	CENCA: '800-1255',
	'CENCA EN': '1-833-708-8215',
	PERU: '0800-00470',
	ECUADOR: '1800-565-565',
	PANAMA: '800 1255',
	URUGUAY: '0800-2550',
};

// Country to flag mapping
export const COUNTRY_TO_FLAG = {
	UK: 'uk-flag.png',
	FR: 'fr-flag.png',
	CH: 'ch-flag.png',
	ARGENTINA: 'ag-flag.svg',
	MEXICO: 'mx-flag.svg',
	PERU: 'pe-flag.svg',
	BRAZIL: 'br-flag.svg',
	ECUADOR: 'ec-flag.svg',
	'COSTA RICA': 'cr-flag.svg',
	URUGUAY: 'uy-flag.svg',
	CHILE: 'cl-flag.svg',
	COLOMBIA: 'co-flag.svg',
};

export type UserStatus = 'ACTIVE' | 'INACTIVE';

export const COUNTRIES = [
	{
		value: 'ARGENTINA',
		label: 'ARGENTINA',
	},
	{
		value: 'BRAZIL',
		label: 'BRAZIL',
	},
	{
		value: 'CHILE',
		label: 'CHILE',
	},
	{
		value: 'COLOMBIA',
		label: 'COLOMBIA',
	},
	{
		value: 'ECUADOR',
		label: 'ECUADOR',
	},
	{
		value: 'MEXICO',
		label: 'MEXICO',
	},
	{
		value: 'CENCA',
		label: 'CENCA',
	},
	{
		value: 'PERU',
		label: 'PERU',
	},
	{
		value: 'PUERTO RICO',
		label: 'PUERTO RICO',
	},
	{
		value: 'URUGUAY',
		label: 'URUGUAY',
	},
];