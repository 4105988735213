import { Modal } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
	AccountList,
	AccountOrders,
	ErrorPage,
	Help,
	HelpExample,
	HelpLineItems,
	HelpOrderStatus,
	HelpOrderStatusWork,
	LoginScreen,
	Onboarding1,
	Onboarding2,
	Onboarding3,
	Onboarding4,
	Onboarding5,
	Onboarding6,
	Onboarding7,
	Onboarding8,
	OrderHead,
	OrderIndex,
	// ProductSubstitution,
	Profile,
	UserList,
	UserDetail,
	OKRs,
	UserNew,
} from './pages';
import { isLoggedIn, showLogin } from './services/AuthService';
import { handleErrors } from './services/HttpService';

function App(): JSX.Element {
	const { t } = useTranslation();

	const onIdle = () => {
		sessionStorage.removeItem('fsrToken');
		sessionStorage.removeItem('login_logged');
		Modal.warn({
			title: t('loginIdle.title'),
			content: t('loginIdle.content'),
			onOk: showLogin,
			onCancel: showLogin,
		});
		console.log(
			`Remaining time ends: ${Math.ceil(getRemainingTime() / 60000)} min`,
		);
	};

	const { getRemainingTime } = useIdleTimer({
		timeout: 1000 * 60 * 15, // 15 minutos
		onIdle,
		debounce: 500,
	});

	useEffect(() => {
		if (!isLoggedIn() && window.location.pathname !== '/') {
			showLogin();
		}

		if (screen?.orientation) {
			screen.orientation.addEventListener('change', rotate);
		}
		return () => {
			screen?.orientation?.removeEventListener('change', rotate);
		};
	}, []);

	const fullScreenCheck = (): Promise<void> | undefined => {
		if (document.fullscreenElement) return;
		return document.documentElement.requestFullscreen();
	};

	const rotate = async () => {
		try {
			await fullScreenCheck();
		} catch (err: unknown) {
			handleErrors(err as Error);
		}
		await screen.orientation.lock('portrait');
	};

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LoginScreen />} />
				<Route path="/login" element={<LoginScreen />} />
				<Route path="/profile" element={<Profile />} />
				<Route path="/help" element={<Help />} />
				<Route path="/help/order-status" element={<HelpOrderStatus />} />
				<Route
					path="/help/order-status-work"
					element={<HelpOrderStatusWork />}
				/>
				<Route path="/help/example" element={<HelpExample />} />
				<Route path="/help/line-items" element={<HelpLineItems />} />
				<Route path="/accounts" element={<AccountList />} />
				<Route
					path="/accounts/updates"
					element={<AccountList hasUpdates={true} />}
				/>
				<Route path="/accounts/:accountId" element={<AccountOrders />} />
				<Route
					path="/accounts/:accountId/order/:orderId"
					element={<OrderHead />}
				/>
				<Route path="/orders" element={<OrderIndex />} />
				<Route path="/onboarding/1" element={<Onboarding1 />} />
				<Route path="/onboarding/2" element={<Onboarding2 />} />
				<Route path="/onboarding/3" element={<Onboarding3 />} />
				<Route path="/onboarding/4" element={<Onboarding4 />} />
				<Route path="/onboarding/5" element={<Onboarding5 />} />
				<Route path="/onboarding/6" element={<Onboarding6 />} />
				<Route path="/onboarding/7" element={<Onboarding7 />} />
				<Route path="/onboarding/8" element={<Onboarding8 />} />
				{/* // Deactivate productSubtitution API due to the execution
				 <Route
					path="/order/:orderId/product-substitution/:lineItemId"
					element={<ProductSubstitution />}
				/> */}
				<Route path="/profile/admin/users" element={<UserList />} />
				<Route path="/profile/admin/users/:userWWID" element={<UserDetail />} />
				<Route path="/profile/admin/okrs" element={<OKRs />} />
				<Route path="/profile/admin/new-user" element={<UserNew />} />
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
