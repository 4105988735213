import { Alert, Flex, Input, List, Spin, Typography } from 'antd';
import { useEffect, ReactElement, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UserListItem, AppLayout } from '../components';
import '../styles/UserList.scss';
import { BASE_TITLE } from '../utils/utility';
import { FsrToken, User, UsersDataResponse } from '../types/adminPortal';
import InfiniteScroll from 'react-infinite-scroll-component';

const URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN_PORTAL_API}/users`;
const { Search } = Input;

export default function UserList(): ReactElement {
	const { t } = useTranslation();
	const [query, setQuery] = useState<string | null>(null);
	const [pageNumber, setPageNumber] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [users, setUsers] = useState<User[]>([]);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorCode, setErrorCode] = useState<number | undefined | null>(null);
	const abortControllerRef = useRef<AbortController | null>(null);

	const fetchUsers = async () => {
		setIsLoading(true);
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		const abortController = new AbortController();
		abortControllerRef.current = abortController;

		const token: FsrToken | null = (() => {
			const storedToken = sessionStorage.getItem('fsrToken');
			if (storedToken === null) {
				return null;
			}
			try {
				return JSON.parse(storedToken) as FsrToken;
			} catch {
				return null;
			}
		})();

		const queryParams = new URLSearchParams({
			offset: pageNumber.toString(),
			limit: '10',
			fsr: 'true',
			validation: 'false',
		});
		if (query) {
			if (/^\d+$/.test(query)) {
				queryParams.append('id', query);
			} else {
				queryParams.append('email', query);
			}
		}
		try {
			const response = await fetch(`${URL}?${queryParams.toString()}`, {
				signal: abortController.signal,
				headers: {
					Authorization: `Bearer ${token?.access_token}`,
				},
			});
			if (!response.ok) {
				const errorData: UsersDataResponse<User> = await response.json();
				setErrorCode(errorData.error?.errorCode);
			}
			const data: UsersDataResponse<User> = await response.json();
			setUsers(prevUsers => [...prevUsers, ...data.user]);
			setHasMore(data.user.length > 0);
			setPageNumber(prevPage => prevPage + 1);
			setIsError(false);
		} catch (error) {
			if (error instanceof Error && error.name === 'AbortError') {
				return;
			}
			setIsError(true);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		document.title = BASE_TITLE + ' - Admin User List';
	}, []);

	useEffect(() => {
		fetchUsers();
		return () => {
			abortControllerRef.current?.abort();
		};
	}, [query]);

	const handleSearch = (value: string) => {
		setQuery(value);
		setUsers([]);
		setPageNumber(0);
		setHasMore(true);
		setIsError(false);
	};

	return (
		<AppLayout topBarProps={{ backVisibility: true }}>
			<div>
				<Typography.Title level={3} className="title-users  py-1 px-1">
					{t('adminPortal.userList.title')}
				</Typography.Title>

				<div className="search-container">
					<Search
						placeholder={t('adminPortal.userList.search')}
						onSearch={handleSearch}
						onChange={event => handleSearch(event.target.value)}
						enterButton
						allowClear
						size="large"
					/>
					{isError ? (
						<Alert
							type="error"
							message={t(`adminPortal.errors.${errorCode}`)}
							className="search-error"
						/>
					) : null}
				</div>

				<div id="user-list" className="users__list__container">
					<InfiniteScroll
						dataLength={users.length}
						next={fetchUsers}
						hasMore={hasMore}
						loader={
							isLoading ? (
								<Flex justify="center" align="center">
									<Spin size="large" />
								</Flex>
							) : null
						}
						scrollableTarget="user-list"
						style={{ overflow: 'hidden' }}
					>
						<List
							dataSource={users}
							split={false}
							renderItem={item => (
								<List.Item key={item.fsr_number}>
									<UserListItem item={item} />
								</List.Item>
							)}
						/>
					</InfiniteScroll>
				</div>
			</div>
		</AppLayout>
	);
}
