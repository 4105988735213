import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import {
	Card,
	Col,
	Row,
	Typography,
	Divider,
	Badge,
	Space,
	Switch,
	Popconfirm,
	message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import './User.scss';
import { User, 	FsrToken } from '../../types/adminPortal';
import dayjs from 'dayjs';

type UserStatus = 'ACTIVE' | 'INACTIVE';

interface OrderProps {
	user: User | null;
	loading: boolean;
	onEdit: () => void;
	onStatus: (checked: boolean) => void;
}

const getColor = (status: UserStatus): string => {
	return status === 'ACTIVE' ? 'green' : 'red';
};

const geStatusBoolean = (status: UserStatus): boolean => {
	return status === 'ACTIVE' ? true : false;
};

const token: FsrToken | null = (() => {
	const storedToken = sessionStorage.getItem('fsrToken');
	if (storedToken === null) {
		return null;
	}
	try {
		return JSON.parse(storedToken) as FsrToken;
	} catch {
		return null;
	}
})();

const UserCard: React.FC<OrderProps> = ({
	user,
	loading,
	onEdit,
	onStatus,
}) => {
	const { t } = useTranslation();
	const isActive = geStatusBoolean(user?.status as UserStatus);

	const activateUser = async (): Promise<void> => {
		const baseUrl = process.env.REACT_APP_BASE_URL;
		const api = process.env.REACT_APP_ADMIN_PORTAL_API;
		const URL = `${baseUrl}${api}/users/${user?.fsr_number}`;
		const response = await fetch(URL, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token?.access_token}`,
			},
			body: JSON.stringify({ status: 'ACTIVE' }),
		});

		const responseData = await response.json();

		if (responseData.error) {
			throw responseData;
		}

		return responseData;
	};

	const deactivateUser = async (): Promise<void> => {
		const baseUrl = process.env.REACT_APP_BASE_URL;
		const api = process.env.REACT_APP_ADMIN_PORTAL_API;
		const URL = `${baseUrl}${api}/users/${user?.fsr_number}`;
		const response = await fetch(URL, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token?.access_token}`,
			},
		});

		const responseData = await response.json();

		if (responseData.error) {
			throw responseData;
		}

		return responseData;
	};

	const confirm = () => {
		return new Promise(resolve => {
			const action = isActive ? deactivateUser : activateUser;
			action()
				.then(response => {
					console.log(response);
					onStatus(!isActive);
					resolve(null);
				})
				.catch(error => {
					console.log(error);
					const errorCode = error.error?.errorCode || 'UNKNOWN_ERROR';
					const errorMessage = t(`adminPortal.errors.${errorCode}`);
					message.error(errorMessage);
					console.error('Error updating status:', error);
					resolve(null);
				});
		});
	};

	return (
		<Col span={24} className="user">
			<Card className="user-card" loading={loading}>
				<Row>
					<Col span={24}>
						<Row>
							<Col span={24}>
								<Row justify="space-between">
									<Col span={1}>
										<div className="badge-container">
											<Badge dot color={getColor(user?.status as UserStatus)} />
										</div>
									</Col>
									<Col span={user?.fsr_profile !== 'reps' ? 18 : 22}>
										<Typography.Text strong className="user-item-title">
											{user?.email}
										</Typography.Text>
									</Col>
									{user?.fsr_profile !== 'reps' ? (
										<Col span={4}>
											<Row>
												<Col span={12}>
													<EditOutlined onClick={onEdit} />
												</Col>
												<Col span={12}>
													<Popconfirm
														title={t(
															`adminPortal.userDetail.userCard.userStatus.${isActive ? 'inactivate' : 'activate'}`,
														)}
														description={t(
															`adminPortal.userDetail.userCard.userStatus.confirm${isActive ? 'Inactivate' : 'Activate'}`,
														)}
														okText={t(
															`adminPortal.userDetail.userCard.userStatus.${isActive ? 'inactivateButton' : 'activateButton'}`,
														)}
														cancelText={t(
															'adminPortal.userDetail.userCard.userStatus.cancelButton',
														)}
														onConfirm={confirm}
													>
														<Switch
															checked={isActive}
															size="small"
															style={{
																background: getColor(
																	user?.status as UserStatus,
																),
															}}
														/>
													</Popconfirm>
												</Col>
											</Row>
										</Col>
									) : null}
								</Row>
							</Col>
						</Row>
						<Divider className="divider-user-card" />
						<Row>
							<Col span={24}>
								<Space.Compact direction="vertical" size="small">
									<Space>
										<Typography.Text className="text-items" strong>
											{t('adminPortal.userDetail.userCard.name')}:
										</Typography.Text>
										<Typography.Text className="text-items">
											{user?.fsr_name}
										</Typography.Text>
									</Space>
									<Space>
										<Typography.Text className="text-items" strong>
											{t('adminPortal.userDetail.userCard.WWID')}:
										</Typography.Text>
										<Typography.Text className="text-items">
											{user?.fsr_number}
										</Typography.Text>
									</Space>
									<Space>
										<Typography.Text className="text-items" strong>
											{t('adminPortal.userDetail.userCard.country')}:
										</Typography.Text>
										<Typography.Text className="text-items">
											{user?.fsr_country}
										</Typography.Text>
									</Space>
									<Space>
										<Typography.Text className="text-items" strong>
											{t('adminPortal.userDetail.userCard.role')}:
										</Typography.Text>
										<Typography.Text className="text-items">
											{user?.fsr_profile}
										</Typography.Text>
									</Space>
									<Space>
										<Typography.Text className="text-items" strong>
											{t('adminPortal.userDetail.userCard.lastLogin')}:
										</Typography.Text>
										<Typography.Text className="text-items">
											{dayjs(user?.last_login_date_time).format('DD/MM/YYYY')}
										</Typography.Text>
									</Space>
									<Space>
										<Typography.Text className="text-items" strong>
											{t('adminPortal.userDetail.userCard.loginCounter')}:
										</Typography.Text>
										<Typography.Text className="text-items">
											{user?.login_monthly_counter}
										</Typography.Text>
									</Space>
								</Space.Compact>
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>
		</Col>
	);
};

export default UserCard;
