export const getProductImagesToken = async () => {
	const details = {
		client_id: process.env.REACT_APP_IMAGE_CLIENT_ID,
		client_secret: process.env.REACT_APP_IMAGE_CLIENT_SECRET,
	};

	let formBody = [];
	for (const property in details) {
		if (details[property]) {
			const encodedKey = encodeURIComponent(property);
			const encodedValue = encodeURIComponent(details[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
	}
	formBody = formBody.join('&');
	const res = await fetch(
		process.env.REACT_APP_IMAGE_URL +
			'/oauth/client_credential/accesstoken?grant_type=client_credentials',
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				origin: `${process.env.REACT_APP_IMAGE_URL}`,
			},
			body: formBody,
		},
	);
	return await res.json();
};

export const getProductImage = async productCode => {
	const imageToken = JSON.parse(sessionStorage.getItem('imageToken'));
	try {
		const res = await fetch(
			process.env.REACT_APP_IMAGE_URL + '/sc-md-b2b/v1/products/' + productCode,
			{
				method: 'GET',
				headers: new Headers({
					'Content-Type': 'application/json',
					Authorization: `Bearer ${imageToken.access_token}`,
					ucn: 'scworx',
				}),
			},
		);
		return await res.json();
	} catch {
		return console.log(`Could not load image for ${productCode}!`);
	}
};
