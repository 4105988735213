import { BottomNavigation, TopBar } from '../index';
import './AppLayout.scss';

type AppLayoutProps = {
  children: React.ReactNode;
  topBarProps?: {
    backVisibility?: boolean;
    logoVisibility?: boolean;
    callVisibility?: boolean;
  }
  bottomNavigationVisibility?: boolean;
}

export default function AppLayout({
  children,
  topBarProps = {
    backVisibility: false,
    logoVisibility: true,
    callVisibility: true,
  },
  bottomNavigationVisibility = true
}: AppLayoutProps) {
  return (
    <section id="AppLayout" className="appLayout">
      <TopBar {...topBarProps} />
      {children}
      {bottomNavigationVisibility && <BottomNavigation />}
    </section>
  );
}
