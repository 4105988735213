import { UIEvent } from 'react';

interface ScrollParams {
    event: UIEvent<HTMLDivElement>;
    currentDataLength: number;
    isLoading: number;
    totalItemCount?: number;
    currentPage: string;
    itemsPerPage: number;
}

export const handleGeneralScroll = ({
    event,
    currentDataLength,
    isLoading,
    totalItemCount,
    currentPage,
    itemsPerPage,
}: ScrollParams): boolean => {
    const bottom = event.currentTarget.scrollHeight - event.currentTarget.scrollTop - event.currentTarget.clientHeight;
    const hasReachedBottom = bottom < 2;
    const hasMoreItemsToLoad = totalItemCount === undefined || currentDataLength < totalItemCount;
    const isNotLoading = isLoading <= 0;
    const nextPage = Number(currentPage) + 1;
    const hasLoadedCurrentPageCompletely = currentDataLength === nextPage * itemsPerPage;
    return hasReachedBottom && hasMoreItemsToLoad && isNotLoading && hasLoadedCurrentPageCompletely;
};