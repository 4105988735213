import { Progress } from 'antd';
import React, { useEffect, useState } from 'react';

interface CircularProgressBarProps {
  percentage: number;
  status?: 'success' | 'exception' | 'normal' | 'active';
  width?: 'default' | 'small';
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  percentage,
  status = 'normal',
  width = 'default',
}) => {
  const [seconds, setSeconds] = useState<number>(percentage);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Progress
      type="circle"
      percent={100 - seconds * 10}
      status={status}
      size={width}
    />
  );
};

export default CircularProgressBar;
