import { Button, Form, Input, Modal, Rate, Result } from 'antd';
import type { FormProps } from 'antd';
import { useTranslation } from 'react-i18next';

import './FeedbackForm.scss';
import { useEffect, useState } from 'react';
import { useFetch } from '../../hooks/useFetch/useFetch';
import { FsrToken } from '../../types/adminPortal';

type FieldType = {
	rating?: number;
	comment?: string;
};

type FeedbackData = {
	status: number;
	user: string;
};

const FeedbackForm: React.FC = () => {
	const { t } = useTranslation();
	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const { state, run } = useFetch<FeedbackData>();
	const [form] = Form.useForm();

	const onFinish: FormProps<FieldType>['onFinish'] = values => {
		const url = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN_PORTAL_API}/users/feedback`;

		const token: FsrToken | null = (() => {
			const storedToken = sessionStorage.getItem('fsrToken');
			if (storedToken === null) {
				return null;
			}
			try {
				return JSON.parse(storedToken) as FsrToken;
			} catch {
				return null;
			}
		})();

		const body = {
			rating: values?.rating,
			fsr_number: Number(localStorage.getItem('fsr_number')),
			fsr_name: localStorage.getItem('fsr_name'),
			...(values?.comment && { comments: values?.comment }),
		};

		const options = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${token?.access_token}`,
				'Content-Type': 'application/json; charset=UTF-8',
			},
		};
		run(url, options, {});
	};

	const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	const modalClose = () => {
		setOpenSuccessModal(false);
		form.resetFields();
	};

	useEffect(() => {
		if (state?.data?.status === 201) {
			setOpenSuccessModal(true);
		}
		return () => {
			setOpenSuccessModal(false);
		};
	}, [state.data]);

	return (
		<>
			<Form
				name="feedback"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
				className="feedback-form"
				form={form}
			>
				<Form.Item<FieldType>
					label={t('feedbackForm.ratingLabel')}
					className="feedback-form__label"
					name="rating"
					rules={[
						{ required: true, message: t('feedbackForm.ratingErrorMessage') },
					]}
				>
					<Rate className="feedback-form__rating" />
				</Form.Item>
				<Form.Item<FieldType>
					label={t('feedbackForm.commentLabel')}
					className="feedback-form__label"
					name="comment"
					rules={[{ required: false }]}
				>
					<Input.TextArea
						className="feedback-form__comment"
						placeholder={t('feedbackForm.commentPlaceholder')}
					/>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						size="large"
						loading={state?.isLoading}
					>
						{t('feedbackForm.submitButton')}
					</Button>
				</Form.Item>
			</Form>
			<Modal
				centered
				open={openSuccessModal}
				footer={null}
				closable
				destroyOnClose
				onCancel={modalClose}
			>
				<Result
					status="success"
					title={t('feedbackForm.successTitle')}
					subTitle={t('feedbackForm.successContent')}
				/>
			</Modal>
		</>
	);
};

export default FeedbackForm;
