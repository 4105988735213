import { Button, Flex, Image } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from '../../context/StoreProvider';
import { ACTIONS } from '../../context/storeReducer';
import './BottomNavigation.scss';

export default function BottomNavigation() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isAccountsRoute = location.pathname.match(/^\/account[s]?\b.*$/g);

	return (
		<Flex className="bottomNavigation" align="center" justify="space-around">
			<Button
				title="orders"
				className="bottomNavigation__button"
				icon={
					<Image
						preview={false}
						src={
							location.pathname.match(/^\/orders/g) ||
							location.pathname.indexOf('product-substitution') > -1
								? '/icons/navigation-cart-active.png'
								: '/icons/navigation-cart.png'
						}
					/>
				}
				onClick={() => {
					dispatch({
						type: ACTIONS.RESET_FILTERS,
					});
					navigate('/orders', { state: location });
				}}
			/>
			<Button
				title="accounts"
				className="bottomNavigation__button"
				icon={
					<Image
						preview={false}
						src={
							isAccountsRoute
								? '/icons/navigation-options-active.png'
								: '/icons/navigation-options.png'
						}
					/>
				}
				onClick={() => {
					dispatch({
						type: ACTIONS.RESET_FILTERS,
					});
					navigate('/accounts/updates', { state: location });
				}}
			/>
			<Button
				title="profile"
				className="bottomNavigation__button"
				icon={
					<Image
						preview={false}
						src={
							location.pathname.match(/^\/profile/g)
								? '/icons/navigation-profile-active.png'
								: '/icons/navigation-profile.png'
						}
					/>
				}
				onClick={() => {
					navigate('/profile', { state: location });
				}}
			/>
			<Button
				title="help"
				className="bottomNavigation__button"
				icon={
					<Image
						preview={false}
						src={
							location.pathname.match(/^\/help/g)
								? '/icons/navigation-help-active.png'
								: '/icons/navigation-help.png'
						}
					/>
				}
				onClick={() => {
					navigate('/help', { state: location });
				}}
			/>
		</Flex>
	);
}
