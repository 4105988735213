/**
 * Builds a query string from a given set of parameters.
 *
 * @param {Object} params - The parameters to be converted into a query string.
 * @returns {string} - The query string representation of the parameters.
 */
export function buildQueryString(params = {}) {
	const queryParams = Object.entries(params)
		.map(([key, value]) => {
			return `${encodeURIComponent(key)}=${encodeURIComponent(value).toString()}`;
		})
		.join('&');

	return queryParams && `?${queryParams}`;
}

/**
 * Constructs a complete URL by combining a base URL and optional query parameters.
 *
 * @param {string} url - The base URL.
 * @param {Object} queryParams - An optional object containing query parameters.
 * @returns {string} - The complete URL with query parameters (if provided).
 */
export function getUrl(url, queryParams = '') {
	return `${url}${queryParams ? buildQueryString(queryParams) : ''}`;
}

/**
 * Handles errors by either rethrowing them or logging them based on the error type.
 *
 * @param {Error} error - The error to be handled.
 * @throws {Error} - Rethrows the error if it's not an 'AbortError'.
 */
export function handleErrors(error) {
	/**
	 * If the error is of type 'AbortError', it is rethrow without further handling.
	 * Otherwise, the error is logged (in non-production environments) and rethrow.
	 */
	if (error.name === 'AbortError') {
		throw error;
	}

	// Log the error in non-production environments.
	if (process.env.NODE_ENV !== 'production') {
		console.log(error); // eslint-disable-line
	}

	// Rethrow the error.
	throw error;
}
